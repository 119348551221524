import { Injectable } from '@angular/core';
import { parseGIF} from 'gifuct-js';

@Injectable({
  providedIn: 'root'
})
export class GifDataService {
  constructor() {}

  async getGifFrameCount(file: File): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (!event.target?.result) {
          reject('Failed to read file.');
          return;
        }

        const arrayBuffer = event.target.result as ArrayBuffer;

        try {
          const gif = parseGIF(new Uint8Array(arrayBuffer));
          const frames = gif.frames;
          resolve(frames.length);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => reject('Error reading file.');
      reader.readAsArrayBuffer(file);
    });
  }

  async getGifFramesForMultiple(files: File[]): Promise<number[]> {
    const promises = Array.from(files).map(file => this.getGifFrameCount(file));
    return Promise.all(promises);
  }
}
