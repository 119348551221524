import {Injectable} from '@angular/core';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {CampaignPreviewComponent} from '../../component/campaign-preview/campaign-preview.component';
import {DialogType} from '../../../shared/enum/ui/dialog/Dialog';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {CampaignsService} from '../campaigns.service';
import {ConfirmDialogService} from '../../../shared/service/dialog/confirm-dialog.service';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {CampaignFormComponent} from '../../component/campaign-form/campaign-form.component';
import {
  CampaignsPerProductListComponent
} from '../../component/campaigns-per-product-list/campaigns-per-product-list.component';
import {ICampaignDetails} from '../../interface/ICampaignDetails';
import {ModuleImporterService} from '../../../shared/service/module-importer/module-importer.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignDialogService {

  constructor(private dialogService: DialogService, private campaignService: CampaignsService,
              private confirmDialogService: ConfirmDialogService, private expandedDialogService: ExpandedDialogService,
              private importerService: ModuleImporterService) {
  }

  public async openCampaignPreviewDialog(campaignId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importCampaignModule();
    this.dialogService.open(CampaignPreviewComponent, {type: DialogType.side, data: {campaignId}, backActionDialog});
  }

  public async openCampaignEditDialog(campaignId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importCampaignModule();
    const campaign: ICampaignDetails = await this.campaignService.getCampaignByInternalId(campaignId);
    const formComponent = this.expandedDialogService.open(CampaignFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isEditMode = true;
    formComponent.instance.initialValue = campaign;
    formComponent.instance.isDialog = true;
  }

  public async openCampaignDeleteDialog(name: string, id: string): Promise<boolean> {
    const dialogRef: DialogRef = await this.confirmDialogService
      .openDeleteDialog({objectName: TranslationPrefix.CAMPAIGN, data: {name}});

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(async (value) => {
        if (value) {
          try {
            await this.campaignService.deleteCampaign(id);
            resolve(true);
          } catch (error) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  public async openCampaignListForProductDialog(productId: string, selectedCampaignId?: string, backActionDialog?: IDialogConfig)
    : Promise<CampaignsPerProductListComponent> {
    await this.importerService.importCampaignModule();
    const component = this.expandedDialogService.open(CampaignsPerProductListComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    component.instance.productId = productId;
    component.instance.selectedCampaignId = selectedCampaignId;
    return component.instance;
  }
}
