import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {faArrowLeft, faFloppyDiskCircleArrowRight} from '@fortawesome/pro-light-svg-icons';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Tab} from '../../../shared/interface/ui/tabs/Tab';
import {EnumTranslateService} from '../../../shared/service/translate/enum-translate.service';
import {WaterfallFormSteps} from '../../enum/WaterfallFormSteps';
import {ActivatedRoute, Router} from '@angular/router';
import {IWaterfallForm} from '../../interface/IWaterfallForm';
import {IProduct} from '../../../product/interface/IProduct';
import {IAdUnitSelectForm} from '../../../ad-unit/interface/IAdUnitSelectForm';
import {adUnitsRequired} from '../../../campaign/util/campaign-form-validators.util';
import {WaterfallService} from '../../service/waterfall.service';
import {firstValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {isStepValid, updateStepsSubLabels} from '../../util/waterfall-form.util';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {mapRegionsToSelect} from '../../../shared/util/region/region.util';
import {IPromotedProductCampaign} from '../../../product/interface/IPromotedProductCampaign';
import {ProductsService} from '../../../product/service/product/products.service';
import {FormStepsHandler} from '../../../shared/class/form-steps-handler';
import {IFormStepsHandler} from '../../../shared/interface/form-tabs/IFormStepsHandler';
import {IFormStepsComponent} from '../../../shared/interface/form-tabs/IFormStepsComponent';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {
  adUnitsForCampaignRequired,
  campaignsRequired,
  creativesRequired,
  platformDefined, platformEnabled, platformForDestinationEnabled,
  platformForDestinationRequired
} from '../../util/waterfall-form.validator';
import {IWaterfallDetails} from '../../interface/IWaterfallDetails';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {PlatformType} from '../../../product/enum/PlatformType';
import {ProductEndpoints, WaterfallEndpoints} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {CampaignsService} from '../../../campaign/service/campaigns.service';
import _ from 'lodash';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';

@Component({
  selector: 'app-waterfall-form',
  templateUrl: './waterfall-form.component.html',
  styleUrls: ['./waterfall-form.component.scss']
})
export class WaterfallFormComponent implements OnInit, IFormStepsComponent, ICanDeactivateFormCmp, OnDestroy {
  @Input() isDialog = false;
  @Input() isEditMode = false;
  @Input() isCopyMode = false;
  @Input() initialValue: IWaterfallDetails;
  @Input() public initialWaterfallId: string;
  @Input() afterCloseRouterLink: string;
  @Output() CopySubmitted = new EventEmitter<void>();

  public canDeactivateForm = false;

  public initialProductId: string;
  public initialStep: string;
  public faArrowLeft = faArrowLeft;
  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;

  public formGroup: FormGroup<IWaterfallForm>;

  public showFullForm = true;

  public steps: Tab[] = [];
  public activeStep: Tab;

  public WaterfallFormSteps = WaterfallFormSteps;
  public closeDialogSubject = new Subject<boolean>();

  public formStepsHandler: IFormStepsHandler;

  public isLoading = false;
  public isFormLoading = false;
  public subscriptions: Subscription[] = [];
  private _productsCampaignsRefresh: Subject<void> = new Subject<void>();
  public productsCampaignsRefresh: Observable<void> = this._productsCampaignsRefresh.asObservable();
  private mismatchedCampaignIds: string[] = [];
  private readonly defaultQueueName = 'default';

  constructor(private formBuilder: FormBuilder, private enumTranslateService: EnumTranslateService,
              private router: Router, private waterfallService: WaterfallService,
              @Optional() @Inject(BACK_ACTION) public backAction: IBackAction, private productService: ProductsService,
              public changeDetectorRef: ChangeDetectorRef, private loadingService: LoadingService,
              private route: ActivatedRoute, private campaignService: CampaignsService, private location: Location,
              private translateService: TranslateService, private snackbarService: SnackbarService) {
    this.initialProductId = this.route.snapshot.queryParamMap.get('productId');
    this.initialWaterfallId = this.route.snapshot.paramMap.get('id');
    this.isEditMode = this.isDialog ? this.isEditMode : this.route.snapshot.data.isEditMode;
    // @ts-ignore
    this.initialStep = this.location.getState()?.stepName;
    // @ts-ignore
    this.afterCloseRouterLink = this.location.getState()?.afterCloseLink;
    this.formStepsHandler = new FormStepsHandler(this);
  }

  private _loadingEndpointNames(): string[] {
    return [ProductEndpoints.getProductByInternalId(this.initialProductId),
    WaterfallEndpoints.getWaterfallByInternalId(this.initialWaterfallId)];
  }

  ngOnInit(): void {
    this.observeLoading();
    this.showFullForm = !(this.isEditMode && this.isDialog);
    this.initData();
  }

  private observeLoading(): void {
    const subscription = this.loadingService.loadingEndpointsObs()
      .subscribe((loadingState) => {
        this.isFormLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingState);
      });
    this.subscriptions.push(subscription);
  }

  private async initData(): Promise<void> {
    await this.initWaterfall();
    this.initForm();
    this.observeFormFields();
    this.initPlatforms();
    await this.initSteps();
    await this.initProductById();
    this.formStepsHandler.updateStepsAfterFormChanges();
  }

  private async initWaterfall(): Promise<void> {
    this.initialValue = this.initialWaterfallId ?
      await this.waterfallService.getWaterfallByInternalId(this.initialWaterfallId) : this.initialValue;
  }

  private async initSteps(): Promise<void> {
    this.steps = await this.getInitialSteps();
    if (this.initialStep) {
      this.activeStep = this.steps.find(step => step.name === this.initialStep) || this.steps[0];
    } else if(this.isCopyMode) {
      this.activeStep = this.steps.find(step => step.name === 'summary') || this.steps[0];
    } else {
      this.activeStep = this.steps[0];
    }
    this.steps.forEach(step => step.isDisabledFn = this.isStepDisabled.bind(this));
  }

  private async getInitialSteps(): Promise<Tab[]> {
    const steps = await this.enumTranslateService.getSelectOptionsFromEnum(WaterfallFormSteps, 'waterfall.waterfallForm.tabs.');
    return this.isEditMode ? steps.slice(1) : steps;
  }

  private async initPlatforms(): Promise<void> {
    const platformOptions: IBasicSelectOption[] = await this.enumTranslateService.getSelectOptionsFromEnum(PlatformType, 'platformType.');
    if (this.initialValue?.platform) {
      const selectedPlatform = platformOptions.find(el => el.name === this.initialValue.platform.type);
      this.formGroup.controls.platform.setValue(selectedPlatform);
    }
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      promotedProductsCampaigns: this.formBuilder.control([],
        [Validators.required, campaignsRequired, creativesRequired,
          platformForDestinationRequired, platformForDestinationEnabled,
          adUnitsForCampaignRequired, this.campaignsMatchedToOrientation.bind(this)]),
      promotingProduct: this.formBuilder.control(this.initialValue?.product || null, [Validators.required]),
      adUnits: this.formBuilder.array([] as FormGroup<IAdUnitSelectForm>[], [adUnitsRequired]),
      confirmationPopup: this.formBuilder.array([] as FormGroup<IAdUnitSelectForm>[]),
      name: [this.initialValue?.name || '', Validators.required],
      platform: this.formBuilder.control(null, [Validators.required, platformDefined, platformEnabled]),
      queueName: this.getQueueNameInit(),
      region: this.formBuilder.control( mapRegionsToSelect(this.initialValue?.regions || []), Validators.required)
    });

    this.showErrorsInSummary();
  }

  private getQueueNameInit(): FormControl<IBasicSelectOption> {
    let queueNameValue;
    if ((this.isEditMode || this.isCopyMode) && this.initialValue?.queueName) {
      queueNameValue = {
        name: this.initialValue.queueName,
        label: this.initialValue.queueName
      };
    } else if ((this.isEditMode || this.isCopyMode) && !this.initialValue?.queueName) {
      queueNameValue = null;
    } else {
      queueNameValue = {name: this.defaultQueueName, label: this.defaultQueueName};
    }
    return this.formBuilder.control(queueNameValue, [Validators.required]);
  }

  private showErrorsInSummary(): void {
    if(this.isEditMode || this.isCopyMode) {
      this.formGroup.markAllAsTouched();
    }
  }

  private async initProductById(): Promise<void> {
    if (this.initialProductId) {
      const product = await this.productService.getProductByInternalId(this.initialProductId) as IProduct;
      this.initialValue = {product, name: null, platform: null, regions: null, placement: null, campaigns: null};
      this.formGroup.controls.promotingProduct.setValue(product);
      this.onNextStepClicked();
    }
  }

  private observeFormFields(): void {
    this.observeProduct();
    this.observeAdUnits();
    this.observePlatform();
    this.observeProductCampaigns();
  }

  private observeProduct(): void {
    const sub = this.formGroup.controls.promotingProduct.valueChanges.subscribe(() => {
      this.formGroup.controls.platform.setValue(null);
      this.updateProductCampaignsValidity();
    });
    this.subscriptions.push(sub);
  }

  private observeAdUnits(): void {
    const sub = this.formGroup.controls.adUnits.valueChanges.subscribe(() => {
      this.updateProductCampaignsValidity();
      this.updateConfirmationPopupValues();
    });
    this.subscriptions.push(sub);
  }

  private observePlatform(): void {
    const sub = this.formGroup.controls.platform.valueChanges.subscribe(() => {
      this.updateProductCampaignsValidity();
    });
    this.subscriptions.push(sub);
  }

  private updateConfirmationPopupValues(): void {
    const adUnits = this.formGroup.controls.adUnits.value;
    const confirmationPopup = this.formGroup.controls.confirmationPopup;
    if (adUnits.length !== confirmationPopup.length) { // if confirmation popup is not initialized yet
      return;
    }
    adUnits.forEach((adUnit, idx) => {
      if (!adUnit.isActive) {
        confirmationPopup.at(idx).controls.isActive.setValue(false);
        confirmationPopup.at(idx).disable();
      } else if (adUnit.isActive && !confirmationPopup.at(idx).enabled) {
        confirmationPopup.at(idx).enable();
      }
    });
  }

  private updateProductCampaignsValidity(): void {
    this.formGroup.controls.promotedProductsCampaigns.updateValueAndValidity({emitEvent: false});
    this._productsCampaignsRefresh.next();
  }

  private observeProductCampaigns(): void {
    this.formGroup.controls.promotedProductsCampaigns.valueChanges.subscribe(async () => {
      await this.setMismatchedCampaignIds();
      this.updateProductCampaignsValidity();
    });
  }

  private async setMismatchedCampaignIds(): Promise<void> {
    const productId = this.formGroup?.controls?.promotingProduct?.value?.id;
    const campaignIds = this.formGroup?.controls?.promotedProductsCampaigns?.value?.map(
      productCampaign => productCampaign?.campaign?.id).filter(el => el);
    if (productId && !_.isEmpty(campaignIds)) {
      try {
        this.mismatchedCampaignIds = await this.campaignService.matchCampaignToProductOrientation(productId, campaignIds);
      } catch (e) {
        this.mismatchedCampaignIds = [];
      }
    }
  }

  public onPromotingProductSelected(product: IProduct): void {
    this.formGroup.controls.promotingProduct.setValue(product);
    this.onNextStepClicked();
  }

  public onSubmitClicked(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.waterfallService.addWaterfall(this.formGroup).then(async () => {
        const message = await firstValueFrom(this.translateService.get('waterfall.waterfallCreatedMessage'));
        this.snackbarService.openSuccessSnackbar(message);
        this.canDeactivateForm = true;
        this.closeForm(true);
      }).finally(() => {
        this.isLoading = false;
        if(this.isCopyMode) {
        this.CopySubmitted.emit();
        }
      });
    }
  }

  public onSubmitEditClicked(): void {
    this.isLoading = true;
    this.waterfallService.updateWaterfall(this.formGroup, this.initialValue.id).then(async () => {
      const message = await firstValueFrom(this.translateService.get('waterfall.waterfallEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.closeForm(true);
    }).finally(() => this.isLoading = false);
  }

  private closeForm(omitConfirmation: boolean, preventBackAction: boolean = false): void {
    if (!this.isDialog) {
      const link = this.afterCloseRouterLink ? this.afterCloseRouterLink : '/waterfall/list';
      this.router.navigate([link]);
    } else {
      this.closeDialog(omitConfirmation, preventBackAction);
    }
  }

  private closeDialog(omitConfirmation: boolean, preventBackAction: boolean = false): void {
    if (preventBackAction) {
      this.preventBackActionDialogOpen();
    }
    const omit = this.showFullForm ? omitConfirmation : true;
    this.closeDialogSubject.next(omit);
  }

  private preventBackActionDialogOpen(): void {
    if (this.backAction) {
      this.backAction.backActionDialogConfig = null;
    }
  }

  public onPromotedProductsCampaignsChange(productCampaign: IPromotedProductCampaign[]) : void {
    this.formGroup.controls.promotedProductsCampaigns.setValue(productCampaign);
  }

  /* Steps */
  public onGoToStepClicked(stepName: WaterfallFormSteps): void {
    if (!this.showFullForm && this.isEditMode) {
      this.router.navigate(['/waterfall/edit', this.initialValue.id], {state: {stepName, afterCloseLink: this.router.url}});
      this.closeForm(true, true);
    } else {
      const idx = this.steps.findIndex(step => step.name === stepName);
      this.goToStep(idx);
    }
  }

  private goToStep(idx: number): void {
    this.activeStep = this.steps[idx];
    updateStepsSubLabels(this.steps, this.formGroup);
  }

  public get activeStepIndex(): number {
    return this.steps.findIndex(step => step.name === this.activeStep.name);
  }

  /* Action buttons logic */
  public isFirstStep(): boolean {
    return this.activeStep?.name === this.steps[0]?.name;
  }

  public onPreviousStepClicked(): void {
    this.goToStep(this.activeStepIndex - 1);
  }

  public onCancelClicked(): void {
    this.closeForm(false);
  }

  public isNextStepButtonVisible(): boolean {
    return this.activeStep?.name !== WaterfallFormSteps.CHOOSE_PROMOTING_PRODUCT &&
      this.activeStep?.name !== this.steps[this.steps.length - 1]?.name && this.showFullForm;
  }

  public isStepValid(step: Tab = this.activeStep): boolean {
    return isStepValid(step, this.formGroup);
  }

  public onNextStepClicked(): void {
    this.goToStep(this.activeStepIndex + 1);
  }

  public isSubmitButtonVisible(): boolean {
    return this.activeStep?.name === WaterfallFormSteps.SUMMARY && !this.isEditMode;
  }

  public isSubmitEditButtonVisible(): boolean {
    return this.showFullForm && this.activeStep?.name === WaterfallFormSteps.SUMMARY && this.isEditMode;
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  public campaignsMatchedToOrientation(formControl: FormControl<IPromotedProductCampaign[]>)
    : ValidationErrors | null {
    const parentForm = formControl?.parent as FormGroup<IWaterfallForm>; // "?" is used, because parentForm is not defined in first call
    const productId = parentForm?.controls?.promotingProduct?.value?.id;
    const campaignIds = formControl.value?.map(productCampaign => productCampaign?.campaign?.id).filter(el => el);
    if (productId && !_.isEmpty(campaignIds)) {
      // filter is needed in case promotingProduct product changes
      const mismatchedCampaignIds = this.mismatchedCampaignIds.filter(id => campaignIds.includes(id));
      return _.isEmpty(mismatchedCampaignIds) ? null : {orientationMismatch: true, mismatchedCampaignIds};
    } else {
      return null;
    }
  }

  /* Selecting steps */
  public onStepSelected(step: Tab): void {
    if (!step.isDisabledFn(step)){
      this.goToStep(this.formStepsHandler.getStepIdx(step));
    }
  }

  public isStepDisabled(step: Tab): boolean {
    return this.formStepsHandler.isStepDisabled(step);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}
