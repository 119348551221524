import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DialogType} from '../../../shared/enum/ui/dialog/Dialog';
import {AssetService} from '../asset.service';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {AssetFormComponent} from '../../component/asset-form/asset-form.component';
import {AssetPreviewComponent} from '../../component/asset-preview/asset-preview.component';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {ConfirmDialogService} from '../../../shared/service/dialog/confirm-dialog.service';
import {IAssetDetails} from '../../interface/IAssetDetails';
import {ModuleImporterService} from '../../../shared/service/module-importer/module-importer.service';
import {ZipUploadDialogComponent} from '../../component/zip-upload-dialog/zip-upload-dialog.component';

/**
 * Service for managing asset dialogs.
 * This service provides methods to open various dialogs related to assets, such as editing, previewing, and deleting.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetDialogService {

  /**
   * Constructor for AssetDialogService.
   * @param translateService Service for language translation.
   * @param assetService The service for managing assets.
   * @param dialogService The service for managing general dialogs.
   * @param expandedDialogService The service for managing expanded dialogs.
   * @param confirmDialogService The service for managing confirmation dialogs.
   * @param importerService The service for importing modules dynamically.
   */
  constructor(private translateService: TranslateService, private assetService: AssetService,
              private dialogService: DialogService, private expandedDialogService: ExpandedDialogService,
              private confirmDialogService: ConfirmDialogService, private importerService: ModuleImporterService) {
  }

  /**
   * Opens the dialog for editing an asset.
   * @param assetId The ID of the asset to edit.
   * @param backActionDialog Configuration for a possible back action dialog.
   */
  public async openAssetEditDialog(assetId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importAssetModule();
    const asset: IAssetDetails = await this.assetService.getAssetById(assetId);
    const component = this.expandedDialogService.open(AssetFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    component.instance.isDialog = true;
    component.instance.isEditMode = true;
    component.instance.initialValue = asset;
  }

  /**
   * Opens the dialog for previewing an asset.
   * @param assetId The ID of the asset to preview.
   */
  public async openAssetPreviewDialog(assetId: string): Promise<void> {
    await this.importerService.importAssetModule();
    this.dialogService.open(AssetPreviewComponent, {type: DialogType.side, data: {asset: {id: assetId}}});
  }

  /**
   * Opens the dialog for deleting an asset.
   * @param assetName The name of the asset to delete.
   * @param id The ID of the asset to delete.
   * @returns A promise resolving to the dialog reference.
   */
  public async openAssetDeleteDialog(assetName: string, id: string): Promise<boolean> {
    const dialogRef: DialogRef = await this.confirmDialogService
      .openDeleteDialog({objectName: TranslationPrefix.ASSET, data: {name: assetName}});

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(async (value) => {
        if (value) {
          try {
            await this.assetService.deleteAsset(id);
            resolve(true);
          } catch (error) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  public openAssetUploadDialog(): DialogRef {
    return this.dialogService.open(ZipUploadDialogComponent, {type: DialogType.center});
  }
}
