import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {faArrowUpRightFromSquare, faCircleInfo, faPenToSquare, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {IAsset} from '../../../asset/interface/IAsset';
import {getResolutionLabel} from '../../../asset/util/resolution.util';
import {CreativeDialogService} from '../../service/creative-dialog/creative-dialog.service';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {AssetLinkService} from '../../../asset/service/asset-link/asset-link.service';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {CreativeService} from '../../service/creative.service';
import {TranslateService} from '@ngx-translate/core';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {CreativeEndpointsEnum} from '../../../shared/enum/Endpoints';
import {ICreativeDetails} from '../../interface/ICreativeDetails';
import {AdUnitService} from '../../../ad-unit/service/ad-unit';
import {IAdUnitFormat} from '../../../ad-unit/interface/IAdUnitFormat';
import {ProductSimple} from '../../../product/interface/IProductSimple';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';

@Component({
  selector: 'app-creative-preview',
  templateUrl: './creative-preview.component.html',
  styleUrls: ['./creative-preview.component.scss']
})
export class CreativePreviewComponent implements OnInit, OnDestroy {
  public faTrash = faTrash;
  public faXMark = faXmark;
  public faPenToSquare = faPenToSquare;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  public faCircleInfo = faCircleInfo;

  public getResolution = getResolutionLabel;

  public subscriptions: Subscription[] = [];

  public creative: ICreativeDetails;
  public isSplitScreen = false;
  public adUnitFormatNames: string[] = [];

  public isLoading = false;
  private loadingEndpointNames: string[] = [CreativeEndpointsEnum.GET_CREATIVE_BY_INTERNAL_ID];
  public isStatic;
  public allAdUnitFormats: IAdUnitFormat[] = [];

  constructor(public dialogRef: DialogRef,
              @Inject(DIALOG_DATA) public data: { creativeId: string }, @Inject(BACK_ACTION) public backAction: IBackAction,
              private creativeDialogService: CreativeDialogService,
              private assetLinkService: AssetLinkService, private creativeService: CreativeService,
              private translateService: TranslateService, private loadingService: LoadingService,
              private adUnitService: AdUnitService, private snackBarService: SnackbarService) {
  }

  public ngOnInit(): void {
    this.initializeCreative();
    this.observeLoading();
  }

  public async initializeCreative(): Promise<void> {
    this.creative = await this.creativeService.getCreative(this.data.creativeId);
    this.isSplitScreen = this.creative.splitScreenProducts?.length > 0;
    this.setAdUnitFormatNames();
  }

  public async setAdUnitFormatNames(): Promise<void> {
    this.allAdUnitFormats = await this.adUnitService.getAdUnitFormatsFromSubject();
    this.adUnitFormatNames = this.creative.assets.map(asset => this.getAssetFormatNames(asset));
    this.setIsStatic();
  }

  private setIsStatic(): void {
    const adUnitFormatIds = this.creative.matchedAssetsToFormats.map(matched => matched.adUnitFormatId);
    const adUnitFormats = this.allAdUnitFormats.filter(adUnitFormat =>
      adUnitFormatIds.includes(adUnitFormat.id));
    this.isStatic = adUnitFormats.some(adUnitFormat => adUnitFormat.isStatic);
  }

  public getAssetFormatNames(asset: IAsset): string {
    const formatsForAsset = this.allAdUnitFormats
      .filter(adUnitFormat => asset.adUnitFormatIds.includes(adUnitFormat.id))
      .map(adUnitFormat => adUnitFormat.name);
    return formatsForAsset.join(', ');
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public async onDeleteCreativeClicked(): Promise<void> {
    const deleted = await this.creativeDialogService.openCreativeDeleteDialog(this.creative.name, this.creative.id);
    if (deleted) {
      this.dialogRef.close();
    }
  }

  public async onEditCreativeClicked(): Promise<void> {
    await this.creativeDialogService.openCreativeEditDialog(this.creative.id,
      getSideDialogConfig(CreativePreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public getAssetType(asset: IAsset): Observable<string> {
    return asset?.duration > 0 ? this.translateService.get('asset.assetType.dynamic'):
      this.translateService.get('asset.assetType.static');
  }

  public isMoreGames(): boolean {
    return this.creative?.adUnit?.type?.toLowerCase()?.includes('moregames');
  }

  public onOpenAssetClicked(asset: IAsset): void {
    this.assetLinkService.openAssetInNewTab(asset);
  }

  public getAssetsForProduct(product: ProductSimple): IAsset[] {
    return this.creative.assets.filter(asset => asset.product.id === product.id);
  }

  public async openSplitScreenPreview(): Promise<void> {
    if (!this.creative.splitScreenHtml) {
      const msg = await firstValueFrom(this.translateService.get('creative.splitScreenHtmlMissing'));
      this.snackBarService.openErrorSnackbar(msg);
    } else {
      window.open(this.creative.splitScreenHtml, '_blank').focus();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }
}
