import {Injectable} from '@angular/core';
import {IAsset} from '../../interface/IAsset';
import {AssetFilter} from '../../enum/AssetFilter';
import {ITableOptions} from '../../../shared/interface/ui/table/ITableOptions';
import {ProductSimple} from '../../../product/interface/IProductSimple';
import {AssetService} from '../asset.service';
import {QueryRaw} from '../../../shared/interface/api/QueryRaw';
import {QueryMapperService} from '../../../shared/service/query-mapper.service';
import {AssetQueryRaw, DurationQueryRaw, SizeQueryRaw} from '../../interface/AssetQueryRaw';
import {AdUnitFormatFilter} from '../../../ad-unit/enum/AdUnitFormatFilter';
import {AssetList} from '../../interface/AssetList';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {INumberRange} from '../../interface/INumberRange';
import {isNumber} from 'lodash';
import {IResolution} from '../../interface/IResolution';
import {RESPONSIVE} from '../../util/ratio.util';
import {AssetRow} from '../../interface/AssetRow';
import {SortDirection} from '../../../shared/enum/ui/table/SortDirection';

/**
 * Injectable service for managing asset tables and queries.
 * This service provides methods for retrieving assets based on table options and filters.
 */
@Injectable({
  providedIn: 'root'
})
export class AssetTableManagerService {

  /**
   * Constructor for AssetTableManagerService.
   * @param assetService The service for managing assets.
   * @param queryMapperService The service for mapping query parameters.
   */
  constructor(private assetService: AssetService, private queryMapperService: QueryMapperService) {
  }


  public async getAdditionalAsset(tableOptions: ITableOptions<IAsset, AssetRow>,
                                      productFilters: ProductSimple[], displayedItemsCount: number)
    : Promise<AssetList> {
    const query: AssetQueryRaw = this.getQuery(tableOptions, productFilters);
    query.PageSize = 1;
    query.Page = displayedItemsCount;
    return await this.assetService.getAssetsPaginated(query);
  }

  /**
   * Retrieves assets based on table options and product filters.
   * @param tableOptions The options for the asset table.
   * @param productFilters The filters for products.
   * @returns A promise resolving to the list of assets.
   */
  public async getAssetsPaginated(tableOptions: ITableOptions<IAsset, AssetRow>,
                                  productFilters: ProductSimple[]): Promise<AssetList> {
    return await this.assetService.getAssetsPaginated(this.getQuery(tableOptions, productFilters));
  }

  /**
   * Retrieves assets for a select input based on table options, ad unit format name, and product filters.
   * @param tableOptions The options for the asset table.
   * @param adUnitFormatName The name of the ad unit format.
   * @param productFilters The filters for products.
   * @returns A promise resolving to the list of assets.
   */
  public async getAssetsForSelect(tableOptions: ITableOptions<IAsset, AssetRow>,
                                  adUnitFormatName: string, productFilters: ProductSimple[] = [])
    : Promise<AssetList> {
    const query = this.getQuery(tableOptions, productFilters);
    query.AdUnitFormats = [adUnitFormatName];
    return await this.assetService.getAssetsPaginated(query);
  }

  /**
   * Constructs a query object based on table options and product filters.
   * @param tableOptions The options for the asset table.
   * @param productFilters The filters for products.
   * @returns The constructed query object.
   */
  private getQuery(tableOptions: ITableOptions<IAsset, AssetRow>, productFilters: ProductSimple[]): AssetQueryRaw {
    const query: QueryRaw = this.queryMapperService.getParams(tableOptions);
    const assetQuery: AssetQueryRaw = {
      ...query,
      AdUnitFormats: this.getFilter(tableOptions, AdUnitFormatFilter.AD_UNIT_FORMAT),
      Sizes: this.getSizeFilter(tableOptions),
      Ratios: this.getFilter(tableOptions, AssetFilter.RATIO),
      Durations: this.getDurationFilter(tableOptions),
      Resolutions: this.getResolutionFilter(tableOptions),
      Formats: this.getFilter(tableOptions, AssetFilter.FORMAT),
      SelectedProducts: productFilters.map(product => product.id)
    };


    // Ensure a default sort is applied if none is selected to maintain order after deletions.
    if (!assetQuery.ColumnName) {
      const defaultSortColumn: keyof AssetRow = 'createdAt';
      assetQuery.ColumnName = defaultSortColumn;
      assetQuery.Direction = SortDirection.descending;
    }
    return assetQuery;
  }

  /**
   * Retrieves a filter from table options based on its name.
   * @param tableOptions The options for the asset table.
   * @param filter The name of the filter.
   * @returns The selected items for the filter.
   */
  private getFilter(tableOptions: ITableOptions<IAsset, AssetRow>, filter: AssetStringFilter): string[] {
    const tableFilter = tableOptions.filters.find(f => f.name === filter);
    return tableFilter?.selectedItems?.map(item => item.name);
  }

  /**
   * Constructs size query objects based on table options.
   * @param tableOptions The options for the asset table.
   * @returns The constructed size query objects.
   */
  private getSizeFilter(tableOptions: ITableOptions<IAsset, AssetRow>): SizeQueryRaw[] {
    const tableFilter = tableOptions.filters.find(f => f.name === AssetFilter.SIZE);
    return tableFilter?.selectedItems?.map((item: IBasicSelectOption & { value?: INumberRange }) => {
      const query: SizeQueryRaw = {Min: item.value?.min};
      if (isNumber(item.value?.max)) {
        query.Max = item.value?.max;
      }
      return query;
    });
  }

  /**
   * Constructs duration query objects based on table options.
   * @param tableOptions The options for the asset table.
   * @returns The constructed duration query objects.
   */
  private getDurationFilter(tableOptions: ITableOptions<IAsset, AssetRow>): DurationQueryRaw[] {
    const tableFilter = tableOptions.filters.find(f => f.name === AssetFilter.DURATION);
    return tableFilter?.selectedItems?.map((item: IBasicSelectOption & { value?: INumberRange }) => {
      const query: DurationQueryRaw = {Min: item.value?.min};
      if (isNumber(item.value?.max)) {
        query.Max = item.value?.max;
      }
      return query;
    });
  }

  /**
   * Constructs resolution filter objects based on table options.
   * @param tableOptions The options for the asset table.
   * @returns The constructed resolution filter objects.
   */
  private getResolutionFilter(tableOptions: ITableOptions<IAsset, AssetRow>): {
    Width: number,
    Height: number
  }[] {
    const tableFilter = tableOptions.filters.find(f => f.name === AssetFilter.RESOLUTION);
    return tableFilter?.selectedItems?.map((item: IBasicSelectOption & { value?: IResolution }) => {
      if (item.name === RESPONSIVE) {
        return {Width: 0, Height: 0};
      } else {
        return {Width: item.value?.width, Height: item.value?.height};
      }
    });
  }
}

/** Type for asset string filters. */
type AssetStringFilter = AdUnitFormatFilter.AD_UNIT_FORMAT | AssetFilter.FORMAT | AssetFilter.RATIO;
