import {ComponentRef, Injectable} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DialogType} from '../../../shared/enum/ui/dialog/Dialog';
import {TranslateService} from '@ngx-translate/core';
import {ProductsService} from '../product/products.service';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {ProductFormComponent} from '../../component/product-form/product-form.component';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {ProductPreviewComponent} from '../../component/product-preview/product-preview.component';
import {
  WaterfallProductListComponent
} from '../../../waterfall/component/waterfall-product-list/waterfall-product-list.component';
import {
  DeleteProductDialogComponent
} from '../../component/delete-product-dialog/delete-product-dialog.component';
import {ModuleImporterService} from '../../../shared/service/module-importer/module-importer.service';

@Injectable({
  providedIn: 'root'
})
export class ProductDialogService {

  constructor(private translateService: TranslateService, private productsService: ProductsService, private dialogService: DialogService,
              private expandedDialogService: ExpandedDialogService, private importerService: ModuleImporterService) {
  }

  public async openWaterfallsListForProduct(productId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importWaterfallModule();
    const component = this.expandedDialogService.open(WaterfallProductListComponent,
      {backActionDialog, type: DialogType.expanded});
    component.instance.productId = productId;
  }

  public async openProductPreview(productId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importProductModule();
    this.dialogService.open(ProductPreviewComponent, {
      backActionDialog,
      type: DialogType.side, data:
        {productId}
    });
  }

  public async openProductAddDialog(backActionDialog?: IDialogConfig, showDialogAfterSave: boolean = false): Promise<void> {
    await this.importerService.importProductModule();
    const formComponent = this.expandedDialogService.open(ProductFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isDialog = true;
    formComponent.instance.showProductSavedDialog = showDialogAfterSave;
  }

  public async openProductEditDialog(productId: string, backActionDialog?: IDialogConfig): Promise<ComponentRef<ProductFormComponent>> {
    await this.importerService.importProductModule();
    const formComponent = this.expandedDialogService.open(ProductFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isEditMode = true;
    formComponent.instance.initialProductId = productId;
    formComponent.instance.isDialog = true;
    return formComponent;
  }

  public async openProductDeleteDialog(productName: string, id: string): Promise<boolean> {
    await this.importerService.importProductModule();
    const dialogRef = this.dialogService.open(DeleteProductDialogComponent, {type: DialogType.center, data: {name: productName}});

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(async (value) => {
        if (value) {
          try {
            await this.productsService.deleteProduct(id);
            resolve(true);
          } catch (error) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }
}
