export enum TableAction {
  delete = 'DELETE',
  show = 'SHOW',
  edit = 'EDIT',
  duplicate = 'DUPLICATE',
  link = 'LINK',
  regenerate = 'REGENERATE',
  download = 'DOWNLOAD',
  copy = 'COPY'
}
