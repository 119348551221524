import { Injectable } from '@angular/core';
import { ICampaign } from 'src/app/campaign/interface/ICampaign';
import { ICampaignRaw } from 'src/app/campaign/interface/ICampaignRaw';
import { ApiCampaignsService } from './api-campaigns.service';
import {firstValueFrom, Subject} from 'rxjs';
import {ProductsService} from '../../product/service/product/products.service';
import {ICampaignDetails} from '../interface/ICampaignDetails';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {CampaignQueryRaw} from '../interface/CampaignQueryRaw';
import {CampaignList} from '../interface/CampaignList';
import {CampaignChange} from '../interface/CampaignChange';


@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  public campaignsChangedSubject: Subject<void | CampaignChange> = new Subject();

  constructor(
    private apiCampaignService: ApiCampaignsService,
    private productsService: ProductsService,
    private apiErrorService: ApiErrorService
  ) {
  }

  private static getDefaultCampaignRaw(campaign: ICampaignDetails): ICampaignRaw {
    return {
      id: campaign.id,
      name: campaign.name,
      default: true,
      startDate: 0,
      endDate: 0,
      productId: campaign.product.id,
      creativeIds: campaign.creatives.map(creative => creative.id),
      adUnitIds: campaign.adUnits.map(adUnit => adUnit.id)
    };
  }

  public async getDefaultCampaignForProduct(productId: string): Promise<ICampaign> {
    let campaignForProduct: ICampaign;
    try {
      campaignForProduct = await firstValueFrom(this.apiCampaignService.getDefaultCampaign(productId));
    } catch (e) {
      // 404 is returned when there is yet no default campaign for product - it's not an error, so we don't want to show snackbar here
      if (e?.status !== 404) {
        this.apiErrorService.showApiErrorSnackbar(e, 'campaign.getDefaultCampaignForProductError');
      }
    }
    return campaignForProduct;
  }

  public async getCampaignByInternalId(campaignId: string): Promise<ICampaignDetails> {
    try {
      return await firstValueFrom(this.apiCampaignService.getCampaign(campaignId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.getCampaignByInternalIdError');
      throw error;
    }
  }

  public async getCampaignsByProduct(productId: string): Promise<ICampaignDetails[]> {
    let campaignsForProduct: ICampaignDetails[] = [];
    try {
      campaignsForProduct = await firstValueFrom(this.apiCampaignService.getCampaignsByProduct(productId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.getCampaignsByProductError');
    }
    return campaignsForProduct;
  }

  public async getCampaignsPaginated(query: CampaignQueryRaw): Promise<CampaignList> {
    try {
      return await firstValueFrom(this.apiCampaignService.getCampaignsPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.getAllCampaignsError');
      throw error;
    }
  }

  public async getCampaignAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiCampaignService.getCampaignAutocomplete());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.getCampaignAutocompleteError');
      throw error;
    }
  }

  public async createCampaign(campaignRaw: ICampaignRaw): Promise<void> {
    try {
      await firstValueFrom(this.apiCampaignService.createCampaign(campaignRaw));
      this.campaignsChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.campaignCreationErrorMessage');
      throw error;
    }
  }

  public async editCampaign(campaignRaw: ICampaignRaw): Promise<void> {
    try {
      await firstValueFrom(this.apiCampaignService.editCampaign(campaignRaw));
      this.campaignsChangedSubject.next({campaignEdited: campaignRaw});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.campaignEditErrorMessage');
      throw error;
    }
  }

  public async deleteCampaign(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiCampaignService.deleteCampaign(id));
      this.campaignsChangedSubject.next({deletedId: id});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.deleteCampaignError');
      throw error;
    }
  }

  public async changeDefaultCampaign(campaignId: string): Promise<void> {
    const campaign = await this.getCampaignByInternalId(campaignId);
    const campaignRaw: ICampaignRaw = CampaignsService.getDefaultCampaignRaw(campaign);

    try {
      await firstValueFrom(this.apiCampaignService.editCampaign(campaignRaw));
      this.campaignsChangedSubject.next({campaignEdited: campaignRaw});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'defaultCampaign.defaultCampaignEditErrorMessage');
      throw error;
    }
  }

  public async matchCampaignToProductOrientation(productId: string, campaignIds: string[]): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiCampaignService.matchCampaignToProductOrientation(productId, campaignIds));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'campaign.matchCampaignToProductOrientationError');
      throw error;
    }
  }
}
