import {ActionIconType} from '../../enum/ui/icons/ActionIconType';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {
  faArrowUpRightFromSquare,
  faClone,
  faEdit,
  faEye,
  faTrash,
  faArrowsRotate,
  faArrowDownToLine, faCopy
} from '@fortawesome/pro-light-svg-icons';

export const ACTION_ICONS_WITHOUT_TOOLTIPS: {type: ActionIconType, tooltip: string, icon: IconDefinition}[] = [
  {type: ActionIconType.PREVIEW, tooltip: null, icon: faEye},
  {type: ActionIconType.EDIT, tooltip: null, icon: faEdit},
  {type: ActionIconType.DELETE, tooltip: null, icon: faTrash},
  {type: ActionIconType.DUPLICATE, tooltip: null, icon: faClone},
  {type: ActionIconType.PREVIEW_IN_NEW_TAB, tooltip: null, icon: faArrowUpRightFromSquare},
  {type: ActionIconType.DELETE_FROM_LIST, tooltip: null, icon: faTrash},
  {type: ActionIconType.REGENERATE, tooltip: null, icon: faArrowsRotate},
  {type: ActionIconType.DOWNLOAD, tooltip: null, icon: faArrowDownToLine},
  {type: ActionIconType.COPY, tooltip: null, icon: faCopy},
];
