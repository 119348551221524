import {Injectable} from '@angular/core';
import {ITableOptions} from '../../../shared/interface/ui/table/ITableOptions';
import {ICampaign} from '../../interface/ICampaign';
import {ProductSimple} from '../../../product/interface/IProductSimple';
import {CampaignsService} from '../campaigns.service';
import {QueryRaw} from '../../../shared/interface/api/QueryRaw';
import {CampaignQueryRaw} from '../../interface/CampaignQueryRaw';
import {QueryMapperService} from '../../../shared/service/query-mapper.service';
import {IDateRangeOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {AdUnitFilter} from '../../../ad-unit/enum/AdUnitFilter';
import {CampaignList} from '../../interface/CampaignList';
import {CampaignRow} from '../../interface/CampaignRow';
import {SortDirection} from '../../../shared/enum/ui/table/SortDirection';

@Injectable({
  providedIn: 'root'
})
export class CampaignTableManagerService {

  constructor(private campaignService: CampaignsService,
              private queryMapperService: QueryMapperService) {
  }

  public async getAdditionalCampaign(tableOptions: ITableOptions<ICampaign, CampaignRow>,
                                      productFilters: ProductSimple[], displayedItemsCount: number)
    : Promise<CampaignList> {
    const query: CampaignQueryRaw = this.getQuery(tableOptions, productFilters);
    query.PageSize = 1;
    query.Page = displayedItemsCount;
    return await this.campaignService.getCampaignsPaginated(query);
  }

  public async getCampaigns(tableOptions: ITableOptions<ICampaign, CampaignRow>, productFilters: ProductSimple[])
    : Promise<CampaignList> {
    const query: CampaignQueryRaw = this.getQuery(tableOptions, productFilters);
    return await this.campaignService.getCampaignsPaginated(query);
  }

  private getQuery(tableOptions: ITableOptions<ICampaign, CampaignRow>, productFilters: ProductSimple[]): CampaignQueryRaw {
    const query: QueryRaw = this.queryMapperService.getParams(tableOptions);
    const campaignQuery: CampaignQueryRaw = {
      ...query,
      AdUnits: this.getAdUnitFilter(tableOptions),
      StartDate: this.getDateFilterStart(tableOptions),
      EndDate: this.getDateFilterEnd(tableOptions),
      SelectedProducts: productFilters.map(product => product.id)
    };


    // Ensure a default sort is applied if none is selected to maintain order after deletions.
    if (!campaignQuery.ColumnName) {
      const defaultSortColumn: keyof CampaignRow = 'name';
      campaignQuery.ColumnName = defaultSortColumn;
      campaignQuery.Direction = SortDirection.ascending;
    }
    return campaignQuery;
  }

  private getDateFilterStart(tableOptions: ITableOptions<ICampaign, CampaignRow>): number {
    const tableFilter = tableOptions.filters.find(f => f.name === 'dateRange');
    const range: IDateRangeOption = tableFilter?.selectedItems?.[0] as IDateRangeOption;
    return range?.start?.getTime();

  }

  private getDateFilterEnd(tableOptions: ITableOptions<ICampaign, CampaignRow>): number {
    const tableFilter = tableOptions.filters.find(f => f.name === 'dateRange');
    const range: IDateRangeOption = tableFilter?.selectedItems?.[0] as IDateRangeOption;
    return range?.end?.getTime();
  }

  private getAdUnitFilter(tableOptions: ITableOptions<ICampaign, CampaignRow>): string[] {
    const tableFilter = tableOptions.filters.find(f => f.name === AdUnitFilter.AD_UNIT);
    return tableFilter?.selectedItems?.map(item => item.name);
  }
}
