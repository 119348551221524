import {Injectable} from '@angular/core';
import {WaterfallService} from '../waterfall.service';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {WaterfallFormComponent} from '../../component/waterfall-form/waterfall-form.component';
import {DialogType} from '../../../shared/enum/ui/dialog/Dialog';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {ConfirmDialogService} from '../../../shared/service/dialog/confirm-dialog.service';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {WaterfallPreviewComponent} from '../../component/waterfall-preview/waterfall-preview.component';
import {IWaterfallDetails} from '../../interface/IWaterfallDetails';
import {ModuleImporterService} from '../../../shared/service/module-importer/module-importer.service';
import {WaterfallBatchDeleteComponent} from '../../component/waterfall-batch-delete/waterfall-batch-delete.component';
import {IConfirmDialogData} from '../../../shared/interface/ui/dialog/IConfirmDialogData';
import {ConfirmDialogComponent} from '../../../shared/component/common/confirm-dialog/confirm-dialog.component';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WaterfallDialogService {

  constructor(private waterfallService: WaterfallService, private expandedDialogService: ExpandedDialogService,
              private confirmDialogService: ConfirmDialogService, private dialogService: DialogService,
              private importerService: ModuleImporterService, private translateService: TranslateService) { }

  public async openWaterfallEditDialog(waterfallId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importWaterfallModule();
    const formComponent = this.expandedDialogService.open(WaterfallFormComponent,
      {backActionDialog, type: DialogType.expanded});
    formComponent.instance.isEditMode = true;
    formComponent.instance.initialWaterfallId = waterfallId;
    formComponent.instance.isDialog = true;
  }

  public async openWaterfallAddDialog(waterfall: Partial<IWaterfallDetails>, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importWaterfallModule();
    const formComponent = this.expandedDialogService.open(WaterfallFormComponent,
      {backActionDialog, type: DialogType.expanded});
    formComponent.instance.initialValue = waterfall as IWaterfallDetails;
    formComponent.instance.isDialog = true;
  }

  public async openWaterfallCopyDialog(waterfallId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importWaterfallModule();

    return new Promise<void>((resolve) => {
      const formComponentRef = this.expandedDialogService.open(WaterfallFormComponent, {
        backActionDialog, type: DialogType.expanded
      });

      formComponentRef.instance.isCopyMode = true;
      formComponentRef.instance.initialWaterfallId = waterfallId;
      formComponentRef.instance.isDialog = true;

      formComponentRef.instance.CopySubmitted.subscribe(() => {
        resolve();
      });
    });
  }

  public async openWaterfallDeleteDialog(name: string, id: string): Promise<boolean> {
    await this.importerService.importWaterfallModule();
    const dialogRef = await this.confirmDialogService.openDeleteDialog({
      objectName: TranslationPrefix.WATERFALL,
      data: { name },
    });

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(async (value) => {
        if (value) {
          try {
            await this.waterfallService.removeWaterfall(id);
            resolve(true);
          } catch (error) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  public async openWaterfallPreviewDialog(waterfallId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importWaterfallModule();
    this.dialogService.open(WaterfallPreviewComponent, {type: DialogType.side, data: {waterfallId}, backActionDialog});
  }

  public openWaterfallDeleteCsv(): DialogRef {
    return this.dialogService.open(WaterfallBatchDeleteComponent, {type: DialogType.center});
  }

  public async openImportErrorsConfirmDialog(): Promise<DialogRef> {
    const data: IConfirmDialogData = {
      header: await firstValueFrom(this.translateService.get('import.acceptWarningsHeader')),
      text: await firstValueFrom(this.translateService.get('import.acceptWarningsMessage')),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.save')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }

}
