import {ComponentRef, Injectable} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DialogType} from '../../../shared/enum/ui/dialog/Dialog';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {CreativeService} from '../creative.service';
import {TranslateService} from '@ngx-translate/core';
import {CreativePreviewComponent} from '../../component/creative-preview/creative-preview.component';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {IProduct} from '../../../product/interface/IProduct';
import {
  ProductCreativesPreviewComponent
} from '../../component/product-creatives-preview/product-creatives-preview.component';
import {CreativeFormComponent} from '../../component/creative-form/creative-form.component';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {ConfirmDialogService} from '../../../shared/service/dialog/confirm-dialog.service';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {ICreativeDetails} from '../../interface/ICreativeDetails';
import {ModuleImporterService} from '../../../shared/service/module-importer/module-importer.service';
import {GifDataService} from '../../../shared/service/file-utils/gif-data.service';

@Injectable({
  providedIn: 'root'
})
export class CreativeDialogService {

  constructor(private dialogService: DialogService, private creativesService: CreativeService,
              private translateService: TranslateService, private expandedDialogService: ExpandedDialogService,
              private confirmDialogService: ConfirmDialogService, private importerService: ModuleImporterService,
              private gifDataService: GifDataService) {
  }

  public async openCreativeEditDialog(creativeId: string, backActionDialog?: IDialogConfig): Promise<ComponentRef<CreativeFormComponent>> {
    await this.importerService.importCreativeModule();
    const creative: ICreativeDetails = await this.creativesService.getCreative(creativeId);

    for (const asset of creative.assets) {
      const assetFile = await this.creativesService.getAssetFile(asset.url, asset.name);
      asset.frames = await this.gifDataService.getGifFrameCount(assetFile);
    }

    const formComponent = this.expandedDialogService.open(CreativeFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isEditMode = true;
    formComponent.instance.initialValue = creative;
    formComponent.instance.isDialog = true;
    return formComponent;
  }

  public async openCreativeAddDialog(initialValue?: Partial<ICreativeDetails>, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importCreativeModule();
    const formComponent = this.expandedDialogService.open(CreativeFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isDialog = true;
    if (initialValue) {
      formComponent.instance.initialValue = initialValue as ICreativeDetails;
    }
  }

  public async openProductCreativesPreview(product: IProduct, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importCreativeModule();
    this.dialogService.open(ProductCreativesPreviewComponent, {
      type: DialogType.side,
      data: {product},
      backActionDialog
    });
  }

  public async openCreativePreviewDialog(creativeId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importCreativeModule();
    this.dialogService.open(CreativePreviewComponent, {type: DialogType.side, data: {creativeId}, backActionDialog});
  }

  public async openCreativeDeleteDialog(name: string, id: string): Promise<boolean> {
    await this.importerService.importCreativeModule();
    const dialogRef: DialogRef = await this.confirmDialogService
      .openDeleteDialog({objectName: TranslationPrefix.CREATIVE, data: {name}});

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(async (value) => {
        if (value) {
          try {
            await this.creativesService.deleteCreative(id);
            resolve(true);
          } catch (error) {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  }
}
