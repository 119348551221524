import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortType} from '../../../shared/enum/ui/table/SortType';
import {getFiltersForAssetList} from '../../util/asset-filters.util';
import {TableAction} from '../../../shared/enum/TableAction';
import {AssetService} from '../../service/asset.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AssetMapperService} from '../../service/asset-mapper/asset-mapper.service';
import {AssetDialogService} from '../../service/asset-dialog/asset-dialog.service';
import {IAsset} from '../../interface/IAsset';
import {firstValueFrom, Subject} from 'rxjs';
import {AssetTableManagerService} from '../../service/asset-table-manager/asset-table-manager.service';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {AssetLinkService} from '../../service/asset-link/asset-link.service';
import {TranslateWrapperService} from '../../../shared/service/translate/translate-wrapper/translate-wrapper.service';
import {TranslationPrefix} from '../../../shared/enum/ui/dialog/TranslationPrefix';
import {TranslateService} from '@ngx-translate/core';
import {TooltipType} from '../../../shared/enum/ui/tooltip/TooltipType';
import {Placement} from '../../../shared/enum/ui/tooltip/Placement';
import {AdUnitFiltersService} from '../../../ad-unit/service/ad-unit-filters.service';
import {ProductSimple} from '../../../product/interface/IProductSimple';
import {AssetList} from '../../interface/AssetList';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {Filter, FilterBasic} from '../../../shared/interface/ui/my-table/filter.model';
import {FilterSelectComponent} from '../../../shared/component/common/filter-select/filter-select.component';
import {TableQuery} from '../../../shared/interface/ui/my-table/table-query.model';
import {AssetSelectRow} from '../../interface/AssetSelectRow';
import {Header} from '../../../shared/interface/ui/my-table/header.model';
import {CellActionsEvent, CellEvent} from '../../../shared/interface/ui/my-table/cell-event.model';
import {ITableSort} from '../../../shared/interface/ui/table/ITableSort';
import {AssetRow} from '../../interface/AssetRow';
import {SortDirection} from '../../../shared/enum/ui/table/SortDirection';

/** Component representing a list of assets for selection. */
@Component({
  selector: 'app-asset-list-select',
  templateUrl: './asset-list-select.component.html',
  styleUrls: ['./asset-list-select.component.scss']
})
export class AssetListSelectComponent implements OnInit {
  /** Input property to specify the ad unit ID. */
  @Input() adUnitId: string;

  /** Input property to specify the ad unit format ID. */
  @Input() adUnitFormatId: string;

  /** Input property to specify the ad unit format name. */
  @Input() adUnitFormatName: string;

  /** Input property to provide information about the associated product. */
  @Input() product: ProductSimple;

  /** Output property emitting the selected asset when chosen. */
  @Output() assetSelected = new EventEmitter<IAsset>();

  /** Search value used for filtering assets. */
  public searchValue = '';

  /** Array containing autocomplete items. */
  public autocompleteItems: string[] = [];

  /** Loading status flag. */
  public isLoading = false;

  private query: TableQuery;
  public rows: AssetSelectRow[] = [];
  public itemsCount: number;
  public headers: Header<AssetSelectRow>[] = [
    {name: 'name', headerName: '', sortType: SortType.alphabetic},
    {name: 'createdAt', headerName: '', sortType: SortType.alphabetic},
    {name: 'productName', headerName: '', sortType: SortType.alphabetic},
    {name: 'format', headerName: ''},
    {name: 'resolution', headerName: ''},
    {name: 'duration', headerName: '', sortType: SortType.numeric, cssClassTh: ['display-none-sm']},
    {name: 'ratio', headerName: '', cssClassTh: ['asset-ratio-column']},
    {name: 'size', headerName: '', sortType: SortType.numeric, cssClassTh: ['display-none-sm']},
    {name: 'adUnitFormat', headerName: ''},
    {name: 'creatives', headerName: '', cssClassTh: ['display-none-xs']},
    {name: 'actions', headerName: ''},
    {name: 'buttons', headerName: ''}
  ];
  public filters: Filter[] = [];
  public noMatchFound = false;
  public changeQuery$: Subject<Partial<TableQuery>> = new Subject<Partial<TableQuery>>();
  public spinnerForInfinityScroll = false;

  /**
   * Constructor for AssetListSelectComponent.
   * @param assetService Service for managing assets.
   * @param route The activated route.
   * @param dialog Material dialog service.
   * @param assetMapperService Service for mapping assets.
   * @param changeDetectorRef Change detector reference.
   * @param router The router service
   * @param assetDialogService The service for managing asset dialogs
   * @param assetTableManager Service for managing asset tables.
   * @param expandedDialog Service for expanded dialogs.
   * @param assetLinkService Service for handling asset links.
   * @param translateWrapperService Service for translating messages.
   * @param translateService Service for language translation.
   * @param adUnitFilterService Service for managing ad unit filters.
   * @param productDialogService The service for managing product dialogs
   */
  constructor(
    private assetService: AssetService, private route: ActivatedRoute,
    public dialog: MatDialog, public assetMapperService: AssetMapperService, private changeDetectorRef: ChangeDetectorRef,
    private router: Router, private assetDialogService: AssetDialogService,
    private assetTableManager: AssetTableManagerService, private expandedDialog: ExpandedDialogService,
    private assetLinkService: AssetLinkService, private translateWrapperService: TranslateWrapperService,
    private translateService: TranslateService, private adUnitFilterService: AdUnitFiltersService,
    private productDialogService: ProductDialogService) {
  }

  /** Initialization method called when the component is created. */
  ngOnInit(): void {
    this.initTranslations();
    this.initAutocompleteItems();
    this.initFilters();
  }

  /** Initializes translations for the component. */
  private async initTranslations(): Promise<void> {
    this.translateWrapperService.translateTableHeader(TranslationPrefix.ASSET, this.headers);
    this.setDurationTooltip();
  }

  /** Initializes autocomplete items for the component. */
  private async initAutocompleteItems(): Promise<void> {
    this.autocompleteItems = await this.assetService.getAssetsAutocomplete();
  }

  /** Initializes filters for the component. */
  private async initFilters(): Promise<void> {
    const adUnitFormatFilters = await this.adUnitFilterService.getAdUnitFormatFilters();
    const filters: Filter[] = [...getFiltersForAssetList(), adUnitFormatFilters];
    filters.forEach(filter => filter.component = FilterSelectComponent);
    this.filters = filters;
  }

  /** Sets the tooltip for the 'duration' column. */
  private async setDurationTooltip(): Promise<void> {
    const text = await firstValueFrom(this.translateService.get('asset.durationTooltip'));
    this.headers.find(col => col.name === 'duration').tooltip = {
      type: TooltipType.TEXT,
      text,
      placement: Placement.TOP,
      cssClass: 'tooltip-primary'
    };
  }

  /**
   * Asynchronous method for updating asset list based on the provided parameters (page, filters, sort).
   * Handles loading state, updates the itemsCount property, maps assets to table rows, and detects changes.
   * @param query Object representing the query, consisting of page, page size, filters and sort.
   */
  public async onQueryUpdated(query: TableQuery): Promise<void> {
    const shouldAppendRows = this.shouldAppendRows(query);
    this.query = query;
    this.initiateSpinnerLoading(shouldAppendRows);
    let assetsTable: AssetList = {data: [], metaData: {totalItems: 0}};
    if (this.product) {
      try {
        assetsTable = await this.getAssetList(query);
      } catch (error) {
        this.stopSpinnerLoading();
        return;
      }
    }
    this.itemsCount = assetsTable.metaData.totalItems;
    this.noMatchFound = this.itemsCount === 0;
    const newRows = await this.assetMapperService.mapAssetsSelectToRows(assetsTable.data);
    this.handleNewRows(newRows, shouldAppendRows);
    this.stopSpinnerLoading();
  }

  private async getAssetList(query: TableQuery): Promise<AssetList> {
    return this.assetTableManager.getAssetsForSelect({
        page: {
          pageIndex: query.page.index - 1,
          pageSize: query.page.size
        },
        sort: {direction: query.sort?.direction, name: query.sort?.name as any},
        filters: query.filters as FilterBasic[],
        searchPhrase: this.searchValue
      },
      this.adUnitFormatName,
      this.product ? [this.product] : []);
  }

  private shouldAppendRows(newQuery: TableQuery): boolean {
    return this.query?.page?.index !== newQuery?.page?.index && newQuery?.page?.index !== 1;
  }

  private handleNewRows(newRows: AssetSelectRow[], shouldAppendRows: boolean): void {
    if (shouldAppendRows) {
      this.rows = [...this.rows, ...newRows];
    } else {
      this.rows = newRows;
    }
  }

  private initiateSpinnerLoading(shouldAppendRows: boolean): void {
    if (shouldAppendRows) {
      this.spinnerForInfinityScroll = true;
    } else {
      this.isLoading = true;
    }
  }

  private stopSpinnerLoading(): void {
    this.isLoading = false;
    this.spinnerForInfinityScroll = false;
  }

  public onCellEvent(event: CellEvent): void {
    const columnName: keyof AssetSelectRow = event.columnName as keyof AssetSelectRow;
    switch (columnName) {
      case 'actions':
        this.onActionsClicked(event as CellActionsEvent);
        break;
      case 'name':
        this.previewAsset(event.item as AssetSelectRow);
        break;
      case 'productName':
        this.previewProduct(event.item as AssetSelectRow);
        break;
      case 'buttons':
        this.onChooseClicked(event.item as AssetSelectRow);
    }
  }

  public onActionsClicked(event: CellActionsEvent): void {
    switch (event.action.name) {
      case TableAction.link:
        this.openAssetLink(event.item as AssetSelectRow);
        break;
    }
  }

  /**
   * Opens the asset preview dialog based on the provided asset table row data.
   * @param assetRow Object representing the data of the selected asset table row.
   */
  public previewAsset(assetRow: AssetSelectRow): void {
    this.assetDialogService.openAssetPreviewDialog(assetRow.asset.id);
  }

  private previewProduct(assetRow: AssetSelectRow): void {
    this.productDialogService.openProductPreview(assetRow.asset.product.id);
  }

  /**
   * Opens the asset link in a new tab based on the provided asset table row data.
   * @param assetRow Object representing the data of the selected asset table row.
   */
  public openAssetLink(assetRow: AssetSelectRow): void {
    this.assetLinkService.openAssetInNewTab({
      name: assetRow.asset.name,
      format: assetRow.asset.format,
      url: assetRow.asset.url
    });
  }

  /**
   * Handles the click event when the 'Choose' is clicked.
   * @param assetRow The asset table row representing the chosen asset.
   */
  public async onChooseClicked(assetRow: AssetSelectRow): Promise<void> {
    const asset = await this.assetService.getAssetById(assetRow.asset.id);
    this.assetSelected.emit(asset);
    this.expandedDialog.close();
  }

  /**
   * Handles the change event when the search value is updated.
   * @param value The new search value.
   */
  public onSearchValueChanged(value: string): void {
    if (this.searchValue !== value) {
      this.searchValue = value;
      this.changeQuery$.next({page: {index: 1}});
    }
  }
}
