import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {CampaignsService} from '../../service/campaigns.service';
import {CustomIconName} from '../../../shared/enum/ui/icons/CustomIconName';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {CreativeDialogService} from '../../../creative/service/creative-dialog/creative-dialog.service';
import {faEdit, faEye, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {CampaignDialogService} from '../../service/campaign-dialog/campaign-dialog.service';
import {CampaignEndpointsEnum} from '../../../shared/enum/Endpoints';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {IDialogConfig} from '../../../shared/interface/ui/dialog/IDialogConfig';
import {ICampaignDetails} from '../../interface/ICampaignDetails';
import {WaterfallDialogService} from '../../../waterfall/service/waterfall-dialog/waterfall-dialog.service';
import {MoreGamesDialogService} from '../../../more-games/service/more-games-dialog.service';
import {IMoreGamesSmallInfo} from '../../../more-games/interface/MoreGamesSmallInfo';
import {IMoreGamesPanelSmallInfo} from '../../../more-games/interface/MoreGamesPanelSmallInfo';
import {Tab} from '../../../shared/interface/ui/tabs/Tab';
import {TranslateService} from '@ngx-translate/core';
import {CampaignPreviewTab} from '../../enum/CampaignPreviewTab';

@Component({
  selector: 'app-campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styleUrls: ['./campaign-preview.component.scss']
})
export class CampaignPreviewComponent implements OnInit, OnDestroy {
  public campaign: ICampaignDetails;
  public adUnitTypes: string[];

  public faEye = faEye;
  public faTrash = faTrash;
  public faEdit = faEdit;
  public faXMark = faXmark;

  public isLoading = false;
  private loadingEndpointNames: string[] = [CampaignEndpointsEnum.GET_CAMPAIGN_BY_INTERNAL_ID];
  public associatedMoreGames: { id: string, name: string, sections: string }[] = [];
  private subscriptions: Subscription[] = [];
  public tabs = [];
  public selectedTab: Tab;
  protected readonly CampaignPreviewTab = CampaignPreviewTab;


  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { campaignId: string },
              @Inject(BACK_ACTION) public backAction: IBackAction, private campaignService: CampaignsService,
              private creativeDialogService: CreativeDialogService, private campaignDialogService: CampaignDialogService,
              private loadingService: LoadingService, private waterfallDialogService: WaterfallDialogService,
              private moreGamesDialogService: MoreGamesDialogService, private translateService: TranslateService) {
  }

  public ngOnInit(): void {
    this.initCampaign();
    this.observeLoading();
  }

  public async initCampaign(): Promise<void> {
    this.campaign = await this.campaignService.getCampaignByInternalId(this.data.campaignId);
    this.adUnitTypes = this.campaign.adUnits?.map(adUnit => adUnit.type) || [];
    this.setAssociatedMoreGames();
    this.initTabs();
  }

  private async initTabs(): Promise<void> {
    this.tabs = [
      {
        label: await firstValueFrom(this.translateService.get('campaign.campaignPreview.creativesTab')),
        name: CampaignPreviewTab.CREATIVES
      },
      {
        label: await firstValueFrom(this.translateService.get('campaign.associatedWaterfalls')),
        name: CampaignPreviewTab.ASSOCIATED_WATERFALLS
      },
      {
        label: await firstValueFrom(this.translateService.get('campaign.associatedMoreGames')),
        name: CampaignPreviewTab.ASSOCIATED_MORE_GAMES
      }];
    this.selectedTab = this.tabs[0];
  }

  /**
   * Maps associated more games to be displayed in the preview.
   * Note: It possible for one campaign to be assigned to multiple panels within one more games screen.
   */
  private setAssociatedMoreGames(): void {
    const associatedMoreGamesMap: Map<string, { name: string, sections: string[] }> = new Map();

    const panelTypes: { key: (keyof IMoreGamesSmallInfo), label: string }[] = [
      {key: 'moreGamesTopPanel', label: 'Top Panel'},
      {key: 'moreGamesMiddlePanel', label: 'Middle Panel'},
      {key: 'moreGamesBottomPanel', label: 'Bottom Panel'},
      {key: 'moreGamesTopPlayablePanel', label: 'Top Playable Panel'},
      {key: 'moreGamesTopVideoPanel', label: 'Top Video Panel'},
      {key: 'moreGamesMiddleVideoPanel', label: 'Middle Video Panel'}
    ];

    panelTypes.forEach(panelType => {
      const moreGames: IMoreGamesPanelSmallInfo[] = this.campaign.moreGames[panelType.key];
      moreGames?.forEach(moreGames => {
        if (associatedMoreGamesMap.has(moreGames.id)) {
          const currentValue = associatedMoreGamesMap.get(moreGames.id);
          associatedMoreGamesMap.set(moreGames.id, {
            ...currentValue,
            sections: [...currentValue.sections, panelType.label]
          });
        } else {
          associatedMoreGamesMap.set(moreGames.id, {name: moreGames.name, sections: [panelType.label]});
        }
      });
    });

    this.associatedMoreGames = Array.from(associatedMoreGamesMap, ([key, value]) => ({
      id: key,
      name: value.name,
      sections: value.sections.join(', ')
    }));
  }

  public onTabChanged(tab: Tab): void {
    this.selectedTab = tab;
  }

  private observeLoading(): void {
    const sub = this.loadingService.loadingEndpointsObs().subscribe((loadingEndpoints) => {
      this.isLoading = this.loadingService.areEndpointsLoading(this.loadingEndpointNames, loadingEndpoints);
    });
    this.subscriptions.push(sub);
  }

  public async onCreativeDeleted(creativeId: string): Promise<void> {
    this.campaign.creatives = this.campaign.creatives.filter(initialCreative => initialCreative.id !== creativeId);
  }

  public getBackDialogConfig(): IDialogConfig {
    return getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data);
  }

  public async onDeleteCampaignClicked(): Promise<void> {
    const deleted = await this.campaignDialogService.openCampaignDeleteDialog(this.campaign.name, this.campaign.id);
    if (deleted) {
      this.dialogRef.close();
    }
  }

  public async onEditCampaignClicked(): Promise<void> {
    await this.campaignDialogService.openCampaignEditDialog(this.campaign.id,
      getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data));
    this.dialogRef.close();
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }

  public getCustomIconName(name: string): CustomIconName {
    return name as CustomIconName;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

  public onWaterfallClicked(waterfallId: string): void {
    this.waterfallDialogService.openWaterfallPreviewDialog(waterfallId,
      getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data));
    this.onCloseClicked();
  }

  public onMoreGamesClicked(moreGamesId: string): void {
    this.moreGamesDialogService.openMoreGamesPreviewDialog(moreGamesId,
      getSideDialogConfig(CampaignPreviewComponent, this.backAction, this.data));
    this.onCloseClicked();
  }

}
