import {Injectable} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {AssetControl, ICreativeFormGroup, SplitScreenSectionGroup} from '../interface/ICreativeFormGroup';
import {IAdUnit} from '../../ad-unit/interface/IAdUnit';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {AdUnitType} from '../../shared/enum/AdUnitType';
import {SplitScreenPlacement, SplitScreenSmallPlacement} from '../enum/SplitScreenPlacement';
import {ProductSimple} from '../../product/interface/IProductSimple';
import {
  matchesFileFormatValidator,
  matchesMaxDurationValidator, matchesMaxFramesValidator,
  matchesMaxSizeValidator,
  matchesRatioValidator,
  matchesResolutionValidator,
  productUniqueValidator
} from '../util/creative-validators.util';
import {IAsset} from '../../asset/interface/IAsset';
import {AssetService} from '../../asset/service/asset.service';
import {CreativeFormStateService} from './creative-form-state.service';
import {HtmlFormatSimple} from '../../asset/enum/HtmlFormatSimple';
import {InterstitialAdUnitFormat} from '../../ad-unit/enum/InterstitialAdUnitFormat';
import {RewardedAdUnitFormat} from '../../ad-unit/enum/RewardedAdUnitFormat';
import {CreativeSplitFormService} from './creative-split-form.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {IProduct} from '../../product/interface/IProduct';
import {CreativeFormUtilService} from './creative-form-util.service';
import {AssetControlData, IAssetFormControl} from '../interface/ICreativeForm';
import {ICreativeDetails} from '../interface/ICreativeDetails';
import {Status} from '../../shared/enum/Status';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CreativeControlsService {
  private assetFormatValidated: Map<string, boolean> = new Map<string, boolean>();
  private splitScreenHtmlValidated: Map<string, boolean> = new Map<string, boolean>();
  // namesUniqueValidated is used to prevent multiple requests for the same name. True if the name is unique, false otherwise.
  private namesUniqueValidated: Map<string, boolean> = new Map<string, boolean>();
  private productSubscriptions: Subscription[] = [];
  private muteSubscriptions: Subscription[] = [];

  constructor(private assetService: AssetService, private creativeFormStateService: CreativeFormStateService,
              private creativeSplitFormService: CreativeSplitFormService, private creativeFormService: CreativeFormUtilService,
              private formBuilder: FormBuilder, private httpClient: HttpClient) {
  }

  public initEditAssetsControls(adUnit: IAdUnit): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    if (!adUnit) {
      this.setAssetControls([], undefined, undefined);
    } else if (this.isRewardedOrInterstitial() && !formGroup.controls.isSplitScreen.value) {
      this.setControlsForRewardedInterstitial(adUnit, true);
    } else if (this.isRewardedOrInterstitial() && formGroup.controls.isSplitScreen.value) {
      this.setControlsForSplitScreen(adUnit, true);
    } else {
      this.setAssetControls(adUnit.adUnitFormats, adUnit.id, adUnit.name, true);
    }
  }

  public setNewAssetsControls(adUnit: IAdUnit): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    if (!adUnit) {
      this.setAssetControls([], undefined, undefined);
    } else if (this.isRewardedOrInterstitial() && !formGroup.controls.isSplitScreen.value) {
      this.setControlsForRewardedInterstitial(adUnit);
    } else if (this.isRewardedOrInterstitial() && formGroup.controls.isSplitScreen.value) {
      this.setControlsForSplitScreen(adUnit);
    } else {
      this.setAssetControls(adUnit.adUnitFormats, adUnit.id, adUnit.name);
    }
  }

  public addOrientationControls(orientation: 'landscape' | 'portrait'): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const isInterstitial = formGroup.controls.adUnit.value.name === 'interstitialAdUnit';
    const isPortrait = orientation === 'portrait';
    const isLandscape = orientation === 'landscape';
    const is4Elements = formGroup.controls.isSplitScreenWith4Elements.value;
    const adUnit = formGroup.controls.adUnit.value;
    const adUnitFormatsForOrientation = this.creativeSplitFormService.getAdFormatsForSplitScreen(adUnit, {
      isLandscape,
      isPortrait,
      is4Elements
    }, isInterstitial);

    formGroup.controls.splitScreenSections.controls.forEach((section) => {
      const newArray = this.getAssetControlsForSplit(adUnitFormatsForOrientation, adUnit.id, adUnit.name);
      if (orientation === 'landscape') {
        newArray.controls.forEach(control => {
          section.controls.assetControls.push(control);
        });
      } else {
        newArray.controls.reverse().forEach(control => {
          section.controls.assetControls.insert(0, control);
        });
      }
    });
  }


  private setAssetControls(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, editInit?: boolean): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const a = this.getAssetControlsArray(adUnitFormats, adUnitId, adUnitName, editInit);
    formGroup.controls.regularAssets.clear();
    a.controls.forEach(el => {
      formGroup.controls.regularAssets.push(el);
    });
  }

  public isRewardedOrInterstitial(): boolean {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const adUnitType = formGroup.controls.adUnit.value?.type;
    return adUnitType === AdUnitType.REWARDED || adUnitType === AdUnitType.INTERSTITIAL;
  }

  private setControlsForRewardedInterstitial(adUnit: IAdUnit, editInit: boolean = false): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const useDefaultHtml = formGroup.controls.useStaticForDefaultHtml.value;
    const adUnitFormats = formGroup.controls.isStatic.value ?
      this.getAdFormatsForStatic(adUnit, useDefaultHtml) :
      this.getAdFormatsForStandardIntRew(adUnit);
    this.setAssetControls(adUnitFormats, adUnit.id, adUnit.name, editInit);
  }

  private getAdFormatsForStandardIntRew(adUnit: IAdUnit): IAdUnitFormat[] {
    const adUnitFormatsToExclude: string[] = [InterstitialAdUnitFormat.VIDEO_SMALL, InterstitialAdUnitFormat.VIDEO_LANDSCAPE_SMALL,
      RewardedAdUnitFormat.VIDEO_SMALL, RewardedAdUnitFormat.VIDEO_LANDSCAPE_SMALL];
    return adUnit.adUnitFormats.filter(adUnitFormat =>
      !adUnitFormat.isStatic && !(adUnitFormatsToExclude.includes(adUnitFormat.name)));
  }

  private getAdFormatsForStatic(adUnit: IAdUnit, useDefaultHtml?: boolean): IAdUnitFormat[] {
    if (useDefaultHtml) {
      return adUnit.adUnitFormats.filter(adUnitFormat => adUnitFormat.isStatic);
    } else {
      return adUnit.adUnitFormats.filter(adUnitFormat =>
        adUnitFormat.formats.includes(HtmlFormatSimple.html) || adUnitFormat.isStatic);
    }
  }

  private setControlsForSplitScreen(adUnit: IAdUnit, editInit: boolean = false): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    const isLandscape: boolean = formGroup.controls.isSplitScreenLandscape.value;
    const isPortrait: boolean = formGroup.controls.isSplitScreenPortrait.value;
    const is4Elements: boolean = formGroup.controls.isSplitScreenWith4Elements.value;
    const isInterstitial = formGroup.controls.adUnit.value.name === 'interstitialAdUnit';
    const adUnitFormats = this.creativeSplitFormService.getAdFormatsForSplitScreen(adUnit, {
      isLandscape,
      isPortrait,
      is4Elements
    }, isInterstitial);

    this.setSplitScreenArray(adUnitFormats, adUnit.id, adUnit.name, editInit);
  }

  private setSplitScreenArray(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, editInit?: boolean): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    let placements;
    if (formGroup.controls.isSplitScreenWith4Elements.value) {
      placements = [SplitScreenPlacement.LEFT_UPPER, SplitScreenPlacement.RIGHT_UPPER,
        SplitScreenPlacement.LEFT_LOWER, SplitScreenPlacement.RIGHT_LOWER];
    } else {
      placements = [SplitScreenSmallPlacement.TOP, SplitScreenSmallPlacement.BOTTOM];
    }

    this.productSubscriptions.forEach(sub => sub?.unsubscribe());
    this.muteSubscriptions.forEach(sub => sub?.unsubscribe());
    formGroup.controls.splitScreenSections.clear();
    placements.forEach((placement, idx) => {
      const group = this.getSplitSectionGroup(adUnitFormats, adUnitId, adUnitName, placement, idx, editInit);
      formGroup.controls.splitScreenSections.push(group);
      this.addListenerToProductSplitScreen(group.controls.product);
    });
    this.addListenerToSplitScreenMute(formGroup.controls.splitScreenSections);
  }

  private getSplitSectionGroup(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string,
                               placement: SplitScreenPlacement, index: number, editInit?: boolean): FormGroup<SplitScreenSectionGroup> {
    const initialValue = this.creativeFormStateService.getInitialValue();
    // @ts-ignore
    if (editInit) {
      const product = initialValue.splitScreenProducts[index];
      const withSound = !initialValue.splitScreenMutedProductIds?.find(el => el === product.id);
      return new FormGroup<SplitScreenSectionGroup>({
        product: new FormControl(product, [Validators.required, productUniqueValidator]),
        withSound: new FormControl(withSound),
        assetControls: this.getAssetControlsForSplit(adUnitFormats, adUnitId, adUnitName, product, true)
      });
    } else {
      return new FormGroup<SplitScreenSectionGroup>({
        product: new FormControl(null, [Validators.required, productUniqueValidator]),
        withSound: new FormControl(index === 0),
        assetControls: this.getAssetControlsForSplit(adUnitFormats, adUnitId, adUnitName, null, false)
      });
    }
  }

  private getAssetControlsForSplit(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string,
                                   product?: IProduct, editInit?: boolean): FormArray<FormGroup<AssetControl>> {
    const assetControls = this.getSplitAssetControls(adUnitFormats, adUnitId, adUnitName, product, editInit)
      .map(el => ({
        adUnitFormat: _.cloneDeep(el.adUnitFormat),
        asset: el.asset,
        adUnitId: el.adUnitId,
      }));

    // @ts-ignore
    const array: FormArray<FormGroup<AssetControl>> = this.formBuilder.array([]);

    assetControls.forEach(assetControl => {
      const assetValidators = [matchesFileFormatValidator, matchesResolutionValidator, matchesRatioValidator,
        matchesMaxSizeValidator, matchesMaxDurationValidator, matchesMaxFramesValidator, Validators.required];
      const asyncAssetValidators = [this.adUnitFormatValidator.bind(this), this.assetNameUniqueValidator.bind(this)];
      if (assetControl.adUnitFormat?.name === RewardedAdUnitFormat.HTML ||
        assetControl.adUnitFormat?.name === InterstitialAdUnitFormat.HTML ||
        assetControl.adUnitFormat?.name === InterstitialAdUnitFormat.HTML_LANDSCAPE ||
        assetControl.adUnitFormat?.name === RewardedAdUnitFormat.HTML_LANDSCAPE) {
        asyncAssetValidators.push(this.splitScreenHtmlValidator.bind(this));
      }
      if (assetControl.adUnitFormat?.name === InterstitialAdUnitFormat.VIDEO ||
        assetControl.adUnitFormat?.name === RewardedAdUnitFormat.VIDEO) {
        assetControl.adUnitFormat.resolution = {width: 180, height: 320}; // the values of adUnitFormat are overridden
        assetControl.adUnitFormat.maxSize = 2621440; // 2.5MB  // the values of adUnitFormat are overridden
      }

      if (assetControl.adUnitFormat?.name === InterstitialAdUnitFormat.VIDEO_LANDSCAPE
        || assetControl.adUnitFormat?.name === RewardedAdUnitFormat.VIDEO_LANDSCAPE) {
        assetControl.adUnitFormat.resolution = {width: 320, height: 180}; // the values of adUnitFormat are overridden
        assetControl.adUnitFormat.maxSize = 2621440; // 2.5MB  // the values of adUnitFormat are overridden
      }
      const group: FormGroup<AssetControl> = new FormGroup<AssetControl>({
        asset: new FormControl(assetControl.asset,
          assetValidators,
          asyncAssetValidators),
        adUnitFormat: new FormControl(assetControl.adUnitFormat),
        adUnitId: new FormControl(assetControl.adUnitId),
        file: new FormControl(null),
        isLandscape: new FormControl(CreativeFormUtilService.isFormatLandscape(assetControl.adUnitFormat)),
      });
      array.push(group);
    });

    return array;
  }

  public getAssetControls(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, editInit?: boolean): IAssetFormControl[] {
    const adUnitFormatsSorted = CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnitName);
    if (editInit) {
      const creative = this.creativeFormStateService.getInitialValue();
      return adUnitFormatsSorted.map(adUnitFormat =>
        this.getAssetControlForEdit(adUnitFormat, adUnitId, creative));
    } else {
      return adUnitFormatsSorted.map(adUnitFormat =>
        this.getAssetControl(adUnitFormat, adUnitId));
    }
  }

  public getSplitAssetControls(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, product?: IProduct, editInit?: boolean): IAssetFormControl[] {
    const adUnitFormatsSorted = CreativeFormUtilService.getFormatsSorted(adUnitFormats, adUnitName);
    if (editInit) {
      const creative = this.creativeFormStateService.getInitialValue();
      return adUnitFormatsSorted.map(adUnitFormat =>
        this.getAssetForSplitEdit(adUnitFormat, adUnitId, creative, product));
    } else {
      return adUnitFormatsSorted.map(adUnitFormat =>
        this.getAssetControl(adUnitFormat, adUnitId));
    }
  }

  private getAssetControl(adUnitFormat: IAdUnitFormat, adUnitId: string): IAssetFormControl {
    return {status: null, adUnitFormat, asset: null, adUnitId};
  }

  private getAssetControlForEdit(adUnitFormat: IAdUnitFormat, adUnitId: string, creative: ICreativeDetails): IAssetFormControl {
    for (const assetFormatPair of creative.matchedAssetsToFormats) {
      if (assetFormatPair.adUnitFormatId === adUnitFormat.id) {
        const asset = creative.assets.find(el => el.id === assetFormatPair.assetId);
        return {status: Status.SUCCESS, adUnitFormat, asset, adUnitId};
      }
    }
    return {status: null, adUnitFormat, asset: null, adUnitId};
  }


  private getAssetForSplitEdit(adUnitFormat: IAdUnitFormat, adUnitId: string,
                               creative: ICreativeDetails, product: IProduct): IAssetFormControl {
    for (const assetFormatPair of creative.matchedAssetsToFormats) {
      if (assetFormatPair.adUnitFormatId === adUnitFormat.id) {
        const asset = creative.assets.find(el => el.id === assetFormatPair.assetId);
        if (asset.product.id === product.id) {
          return {status: Status.SUCCESS, adUnitFormat, asset, adUnitId};
        }
      }
    }
    return {status: null, adUnitFormat, asset: null, adUnitId};
  }

  private addListenerToProductSplitScreen(productControl: FormControl<ProductSimple>): void {
    const parent = productControl.parent as FormGroup<SplitScreenSectionGroup>;
    if (!parent) {
      return;
    }

    const sub = productControl.valueChanges.subscribe(() => {
      parent.controls.assetControls.controls.forEach(assetControl => {
        this.onAssetDeleteForm(assetControl);
        this.updateDuplicationStatus(productControl);
      });
    });
    this.productSubscriptions.push(sub);
  }

  private addListenerToSplitScreenMute(array: FormArray<FormGroup<SplitScreenSectionGroup>>): void {
    array.controls.forEach((group, idx) => {
      const muteControl = group.controls.withSound;
      const sub = muteControl.valueChanges.subscribe((value) => {
        if (value) {
          array.controls.forEach((el, i) => {
            if (i !== idx) {
              el.controls.withSound.setValue(false, {emitEvent: false});
            }
          });
        }
      });
      this.muteSubscriptions.push(sub);
    });

  }

  public onAssetDeleteForm(assetGroup: FormGroup<AssetControl>): void {
    assetGroup.controls.asset.setValue(null);
    assetGroup.controls.file.setValue(null);
    assetGroup.updateValueAndValidity({emitEvent: false});
  }

  private updateDuplicationStatus(omitProduct: FormControl<ProductSimple>): void {
    const formGroup = this.creativeFormStateService.getFormGroup();
    formGroup.controls.splitScreenSections.controls.forEach(group => {
      if (group.controls.product !== omitProduct) {
        group.controls.product.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  private getAssetControlsArray(adUnitFormats: IAdUnitFormat[], adUnitId: string,
                                adUnitName: string, editInit?: boolean): FormArray<FormGroup<AssetControl>> {
    const assetControls: AssetControlData[] = this.getAssetControls(adUnitFormats, adUnitId, adUnitName, editInit).map(el => ({
      adUnitFormat: el.adUnitFormat,
      asset: el.asset,
      adUnitId: el.adUnitId,
    }));

    // @ts-ignore
    const array: FormArray<FormGroup<AssetControl>> = this.formBuilder.array([]);

    assetControls.forEach(assetControl => {
      const assetValidators = [matchesFileFormatValidator, matchesResolutionValidator, matchesRatioValidator,
        matchesMaxSizeValidator, matchesMaxDurationValidator, matchesMaxFramesValidator];
      if (assetControl.adUnitFormat?.required) {
        assetValidators.push(Validators.required);
      }
      const group: FormGroup<AssetControl> = new FormGroup<AssetControl>({
        asset: new FormControl(assetControl.asset,
          assetValidators,
          [this.adUnitFormatValidator.bind(this), this.assetNameUniqueValidator.bind(this)]),
        adUnitFormat: new FormControl(assetControl.adUnitFormat),
        adUnitId: new FormControl(assetControl.adUnitId),
        file: new FormControl(null),
        isLandscape: new FormControl(CreativeFormUtilService.isFormatLandscape(assetControl.adUnitFormat)),
      });


      array.push(group);
    });

    return array;
  }

  private async assetNameUniqueValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    const assetCtrl = control as FormControl<IAsset>;
    if (!assetCtrl.value) {
      return null;
    }
    if (assetCtrl.value.id) { // if asset already exists, do not check
      return null;
    }

    // check duplicates within form
    if (this.isNameDuplicatedSplitScreen(assetCtrl) || this.isNameDuplicatedRegular(assetCtrl)) {
      return {nameDuplicatedError: true};
    }

    const fileName = assetCtrl.value?.originalName;
    if (this.namesUniqueValidated.has(fileName)) {
      return this.namesUniqueValidated.get(fileName) ? null : {nameExistsError: true};
    } else {
      const namePromise = await this.assetService.checkOriginalName(assetCtrl.value?.originalName);
      this.namesUniqueValidated.set(fileName, !namePromise);
      return namePromise ? {nameExistsError: true} : null;
    }
  }

  private isNameDuplicatedSplitScreen(assetCtrl: FormControl<IAsset>): boolean {
    const parentArray = assetCtrl.parent?.parent as FormArray<FormGroup<AssetControl>>;
    const isSplitScreen = !!((parentArray?.parent?.parent?.parent as FormGroup<ICreativeFormGroup>)?.controls?.isSplitScreen?.value);

    if(!isSplitScreen) {
      return false;
    }
    const splitScreenSections = parentArray?.parent?.parent as FormArray<FormGroup<SplitScreenSectionGroup>>;

    return splitScreenSections && splitScreenSections.controls.some(section => {
      return section.controls.assetControls.controls.some(assetGroup => {
        return assetGroup.controls.asset !== assetCtrl && assetGroup?.value.asset?.originalName === assetCtrl.value.originalName;
      });
    });
  }

  private isNameDuplicatedRegular(assetCtrl: FormControl<IAsset>): boolean {
    const parentArray = assetCtrl.parent?.parent as FormArray<FormGroup<AssetControl>>;
    const isSplitScreen = !!((parentArray?.parent?.parent?.parent as FormGroup<ICreativeFormGroup>)?.controls?.isSplitScreen?.value);

    if (isSplitScreen) {
      return false;
    }
    return parentArray && parentArray.controls.some(group => {
      return group.controls.asset !== assetCtrl && group?.value.asset?.originalName === assetCtrl.value.originalName;
    });
  }

  public async adUnitFormatValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    const assetCtrl = control as FormControl<IAsset>;
    if (!assetCtrl?.value || !assetCtrl?.parent) {
      return null;
    } else {
      let adUnitFormats: IAdUnitFormat[];

      if (assetCtrl.value?.frontendId && this.assetFormatValidated.has(assetCtrl.value.frontendId)) {
        const isValid = this.assetFormatValidated.get(assetCtrl.value.frontendId);
        return isValid ? null : {adUnitFormatMatchError: true};
      }
      const newPromise = this.assetService.getAssetAdUnitFormats(assetCtrl.value);
      try {
        adUnitFormats = await newPromise;
      } catch (error) {
        adUnitFormats = [];
      }
      const adUnitFormatId = (assetCtrl.parent as FormGroup<AssetControl>).value.adUnitFormat.id;
      if (!adUnitFormats?.some(el => el.id === adUnitFormatId)) {
        this.assetFormatValidated.set(assetCtrl.value.frontendId, false);
      } else {
        this.assetFormatValidated.set(assetCtrl.value.frontendId, true);
      }
      if (!adUnitFormats?.some(el => el.id === adUnitFormatId)) {
        return {adUnitFormatMatchError: true};
      } else {
        return null;
      }
    }
  }

  public async splitScreenHtmlValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    const assetCtrl = control as FormControl<IAsset>;
    if (!assetCtrl?.value || !assetCtrl?.parent) {
      return null;
    } else {
      if (assetCtrl.value?.frontendId && this.splitScreenHtmlValidated.has(assetCtrl.value.frontendId)) {
        const isValid = this.splitScreenHtmlValidated.get(assetCtrl.value.frontendId);
        return isValid ? null : {endcardSplitScreenError: true};
      }
      let isValid = false;

      const newPromise = this.isEndcardValidSplitScreen(assetCtrl.value.url.startsWith('http'),
        assetCtrl.value.url, assetCtrl.value.htmlString);
      try {
        isValid = await newPromise;
      } catch (error) {
        isValid = true;
      }
      if (!isValid) {
        this.splitScreenHtmlValidated.set(assetCtrl.value.frontendId, false);
      } else {
        this.splitScreenHtmlValidated.set(assetCtrl.value.frontendId, true);
      }
      if (!isValid) {
        return {endcardSplitScreenError: true};
      } else {
        return null;
      }
    }
  }

  private async isEndcardValidSplitScreen(isServer: boolean, url: string, htmlString: string): Promise<boolean> {
    const regex = /var\s+endcardType\s*=\s*'splitScreen';/;

    if (isServer && url) {
      return await firstValueFrom(this.httpClient.get(url, {responseType: 'text'})).then(content => {
        return regex.test(content);
      }).catch(error => {
        console.error('Error fetching the file:', error);
        return true;
      });

    } else if (!isServer && htmlString) {
      return regex.test(htmlString);
    } else {
      return true;
    }
  }

  public markAssetAsValidFormat(id: string): void {
    this.assetFormatValidated.set(id, true);
  }

  public markAssetsAsValidFormat(ids: Set<string>): void {
    ids.forEach(id => this.assetFormatValidated.set(id, true));
  }

  public destroy(): void {
    this.productSubscriptions.forEach(sub => sub?.unsubscribe());
    this.productSubscriptions = [];
    this.muteSubscriptions.forEach(sub => sub?.unsubscribe());
    this.muteSubscriptions = [];
    this.assetFormatValidated = new Map<string, boolean>();
    this.splitScreenHtmlValidated = new Map<string, boolean>();
    this.namesUniqueValidated = new Map<string, boolean>();
  }
}
