import { Injectable } from '@angular/core';
import { RequestType } from 'src/app/shared/enum/RequestType';
import { ICreativeRaw } from 'src/app/creative/interface/ICreativeRaw';
import { ApiGeneralService } from '../../shared/service/api/api-general.service';
import {Observable} from 'rxjs';
import {ICreativeDetails} from '../interface/ICreativeDetails';
import {map} from 'rxjs/operators';
import {CreativeList} from '../interface/CreativeList';
import {QueryService} from '../../shared/service/query.service';

@Injectable({
  providedIn: 'root'
})
export class ApiCreativeService {

  constructor(
      private apiGeneralService: ApiGeneralService,
      private queryService: QueryService
  ) { }

  public getProductCreatives(productId: string): Observable<ICreativeDetails[]> {
    const url = `Creatives/GetPreview/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getCreativeNames(): Observable<string[]> {
    const url = 'Creatives/Names';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map(value => value.filter(el => !!el)));
  }

  public getCreativesPaginated(query: any): Observable<CreativeList> {
    const params = this.queryService.getHttpParams(query);
    const url = 'Creatives/PaginatedAndFiltered';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }


  public createCreative(creative: ICreativeRaw): Observable<void> {
    const url = 'Creatives';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: creative
    });
  }

  public editCreative(creative: ICreativeRaw): Observable<void> {
    const url = 'Creatives';

    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: creative
    });
  }

  public getCreative(id: string): Observable<ICreativeDetails> {
    const url = `Creatives/GetByInternalId/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public deleteCreative(id: string): Observable<void> {
    const url = `Creatives/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  public validateCreativeName(name: string): Observable<boolean> {
    const url = 'Creatives/NameExist/' + encodeURIComponent(name);
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public async getAssetFile(url: string, name:string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    const assetFile: File = new File([blob], name, { type: blob.type });
    return assetFile;
  }
}
