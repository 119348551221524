import {Injectable} from '@angular/core';
import {IWaterfall} from 'src/app/waterfall/interface/IWaterfall';
import {IWaterfallRaw} from 'src/app/waterfall/interface/IWaterfallRaw';
import {ApiWaterfallService} from './api-waterfall.service';
import {IWaterfallForm} from '../interface/IWaterfallForm';
import {FormGroup} from '@angular/forms';
import {firstValueFrom, Subject} from 'rxjs';
import {IPromotedProductCampaign} from '../../product/interface/IPromotedProductCampaign';
import {ProductsService} from '../../product/service/product/products.service';
import {IWaterfallDetails} from '../interface/IWaterfallDetails';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {WaterfallList} from '../interface/WaterfallList';
import {WaterfallQueryRaw} from '../interface/WaterfallQueryRaw';
import {WaterfallChanges} from '../interface/WaterfallChanges';


@Injectable({
  providedIn: 'root'
})
export class WaterfallService {
  public waterfallChangedSubject: Subject<void | WaterfallChanges> = new Subject();

  constructor(
    private apiWaterfallService: ApiWaterfallService, private productService: ProductsService,
    private apiErrorService: ApiErrorService
  ) {
  }

  private static getWaterfallRaw(waterfallForm: FormGroup<IWaterfallForm>): IWaterfallRaw {
    return {
      name: waterfallForm.value.name,
      regions: waterfallForm.value.region.map(option => option.name),
      platformType: waterfallForm.value.platform?.name,
      productId: waterfallForm.value.promotingProduct.id,
      promotedProductIds: this.getPromotedProductsIds(waterfallForm.value.promotedProductsCampaigns),
      campaignIds: this.getCampaignsIds(waterfallForm.value.promotedProductsCampaigns),
      adUnitIds: waterfallForm.value.adUnits.filter(adUnitGroup => adUnitGroup.isActive)
        .map(adUnitGroup => adUnitGroup.adUnit.id),
      confirmationPopup: waterfallForm.value.confirmationPopup.filter(adUnitGroup => adUnitGroup.isActive)
        .map(adUnitGroup => adUnitGroup.adUnit.type),
      queueName: waterfallForm.value.queueName?.name
    };
  }

  private static getPromotedProductsIds(promotedProductsCampaigns: IPromotedProductCampaign[]): string[] {
    return promotedProductsCampaigns.map(productCampaign => productCampaign.product.id);
  }

  private static getCampaignsIds(promotedProductsCampaigns: IPromotedProductCampaign[]): string[] {
    return promotedProductsCampaigns.map(productCampaign => productCampaign.campaign?.id).filter(el => el);
  }

  public async getWaterfallsByProduct(productId: string): Promise<IWaterfall[]> {
    let waterfalls: IWaterfall[] = [];
    try {
      waterfalls = await firstValueFrom(this.apiWaterfallService.getWaterfallsByProductId(productId));
    } catch (error) {
      if (error?.status !== 404) {
        this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.getWaterfallsError');
        throw error;
      }
    }
    return waterfalls;
  }

  public async getAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiWaterfallService.getAutocomplete());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.getWaterfallAutocompleteError');
      throw error;
    }
  }

  public async getWaterfallsPaginated(query: WaterfallQueryRaw): Promise<WaterfallList> {
    try {
      return await firstValueFrom(this.apiWaterfallService.getWaterfallsPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.getWaterfallsError');
      throw error;
    }
  }

  public async getWaterfallByInternalId(waterfallId: string): Promise<IWaterfallDetails> {
    try {
      return await firstValueFrom(this.apiWaterfallService.getWaterfall(waterfallId));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.getWaterfallError');
      throw error;
    }
  }

  public async addWaterfall(waterfallForm: FormGroup<IWaterfallForm>): Promise<void> {
    const waterfallRaw: IWaterfallRaw = WaterfallService.getWaterfallRaw(waterfallForm);

    try {
      await firstValueFrom(this.apiWaterfallService.createWaterfall(waterfallRaw));
      this.waterfallChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.waterfallCreationErrorMessage');
      throw error;
    }

  }

  public async updateWaterfall(waterfallForm: FormGroup<IWaterfallForm>, waterfallId: string): Promise<void> {
    const waterfallRaw: IWaterfallRaw = WaterfallService.getWaterfallRaw(waterfallForm);
    waterfallRaw.id = waterfallId;

    try {
      await firstValueFrom(this.apiWaterfallService.updateWaterfall(waterfallRaw));
      this.waterfallChangedSubject.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.waterfallEditErrorMessage');
      throw error;
    }

  }

  public async removeWaterfall(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiWaterfallService.removeWaterfall(id));
      this.waterfallChangedSubject.next({deletedId: id});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.removeWaterfallError');
      throw error;
    }
  }

  public async exportCsv(): Promise<string> {
    try {
      return await firstValueFrom(this.apiWaterfallService.exportCsv());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'waterfall.exportCsvError');
      throw error;
    }
  }
}
