import { Injectable } from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import { FormGroup } from '@angular/forms';

import { ApiErrorService } from 'src/app/shared/service/api/api-error.service';
import { ApiMoreGamesService } from './api-more-games.service';
import { IMoreGamesList } from '../interface/IMoreGamesList';
import { IMoreGames } from '../interface/IMoreGames';
import { IMoreGamesForm } from '../interface/IMoreGamesForm';
import { IMoreGamesRaw } from '../interface/IMoreGamesRaw';
import { IMoreGamesDetails } from '../interface/IMoreGamesDetails';
import {IMoreGamesChanges} from '../interface/IMoreGamesChanges';
import { ITemplate } from '../interface/IMoreGamesTemplate';
import {IPromotedProductCampaignMG} from '../interface/IPromotedProductCampaignMG';
import {MoreGamesQueryRaw} from '../interface/MoreGamesQueryRaw';

/**
 * Service for managing More Games-related operations and providing communication between components.
 * Utilizes the Subject from RxJS for notifying other components about the need to refresh More Games data.
 */
@Injectable({
  providedIn: 'root'
})
export class MoreGamesService {
  /**
   * Subject used for broadcasting the need to refresh More Games data.
   * Subscribers can listen to this subject to be notified about changes or updates.
   */
  public moreGamesChanged: Subject<void | IMoreGamesChanges> = new Subject();

  /**
   * Constructor for MoreGamesService.
   * @param apiErrorService Instance of the ApiErrorService for handling API errors.
   * @param apiMoreGamesService Instance of the ApiMoreGamesService for interacting with the More Games API.
   */
  constructor(
    private apiErrorService: ApiErrorService,
    private apiMoreGamesService: ApiMoreGamesService
  ) { }

  /**
   * Retrieves a raw More Games object from a form group.
   * @param moreGamesForm The form group containing More Games data.
   * @returns An object representing the raw More Games data.
   */
  private static getMoreGamesRaw(moreGamesForm: FormGroup<IMoreGamesForm>): IMoreGamesRaw {
    const value = moreGamesForm.value;
    return {
      topPanelText: {name: value.topPanelText},
      middlePanelText: { name: value.middlePanelText},
      bottomPanelText: { name: value.bottomPanelText},
      name: value.name,
      productId: value.sourceProduct.id,
      topPanelCampaignIds: value.topPromotedProductsCampaigns.map(el => el.campaign.id),
      middlePanelCampaignIds: value.middlePromotedProductsCampaigns.map(el => el.campaign.id),
      bottomPanelCampaignIds: value.bottomPromotedProductsCampaigns.map(el => el.campaign.id),
      platformId: value.sourceProduct.platforms.find(platform => platform.type === value.platform.name)?.id,
      regions: value.region.map(option => option.name),
      topPanelAdUnitFormatType: value.topPanelAdUnitFormatType.name,
      middlePanelAdUnitFormatType: value.middlePanelAdUnitFormatType.name,
      bottomPanelAdUnitFormatType: value.bottomPanelAdUnitFormatType.name,
      topPromotedProductIds: value.topPromotedProductsCampaigns.map(el => el.product.id),
      middlePromotedProductIds: value.middlePromotedProductsCampaigns.map(el => el.product.id),
      bottomPromotedProductIds: value.bottomPromotedProductsCampaigns.map(el => el.product.id),
      htmlTemplate: value.template.originalKey,
      topVideoPanelText: value.topVideoPanelText ? {name: value.topVideoPanelText} : null,
      middleVideoPanelText: value.middleVideoPanelText ? {name: value.middleVideoPanelText} : null,
      topVideoPanelCampaignIds: value.topVideoPromotedProductsCampaigns.map(el => el.campaign.id),
      middleVideoPanelCampaignIds: value.middleVideoPromotedProductsCampaigns.map(el => el.campaign.id),
      topVideoPromotedProductIds: value.topVideoPromotedProductsCampaigns.map(el => el.product.id),
      middleVideoPromotedProductIds: value.middleVideoPromotedProductsCampaigns.map(el => el.product.id),
      topPlayablePanelText: value.topPlayablePanelText ? {name: value.topPlayablePanelText} : null,
      topPlayablePanelCampaignIds: value.topPlayablePromotedProductsCampaigns.map(el => el.campaign.id),
      topPlayablePromotedProductIds: value.topPlayablePromotedProductsCampaigns.map(el => el.product.id),
    };
  }

  /**
   * Retrieves the IDs of promoted products from an array of promoted product campaigns.
   * @param promotedProductsCampaigns An array of promoted product campaigns.
   * @returns An array of strings representing the IDs of promoted products.
   */
  private static getPromotedProductsIds(promotedProductsCampaigns: IPromotedProductCampaignMG[]): string[] {
    return promotedProductsCampaigns.map(productCampaign => productCampaign.product.id);
  }

  /**
   * Retrieves the IDs of campaigns from an array of promoted product campaigns.
   * @param promotedProductsCampaigns An array of promoted product campaigns.
   * @returns An array of strings representing the IDs of campaigns.
   */
  private static getCampaignsIds(promotedProductsCampaigns: IPromotedProductCampaignMG[]): string[] {
    return promotedProductsCampaigns.map(productCampaign => productCampaign.campaign?.id).filter(el => el);
  }

  /**
   * Retrieves More Games data associated with a specific product ID.
   * @param productId The ID of the product.
   * @returns A promise resolving to an array of More Games objects.
   */
  public async getMoreGamesByProductId(productId: string): Promise<IMoreGames[]> {
    let moreGames: IMoreGames[] = [];
    try {
      moreGames = await firstValueFrom(this.apiMoreGamesService.getMoreGamesByProductId(productId));
    } catch (error) {
      if (error?.status !== 404) {
        this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.getMoreGamesError');
        throw error;
      }
    }
    return moreGames;
  }

  /**
   * Retrieves autocomplete suggestions for More Games.
   * @returns A promise resolving to an array of strings representing autocomplete suggestions.
   */
  public async getAutocomplete(): Promise<string[]> {
    try {
      return await firstValueFrom(this.apiMoreGamesService.getAutocomplete());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.getMoreGamesAutocompleteError');
      throw error;
    }
  }

  /**
   * Retrieves paginated More Games data based on the provided query parameters.
   * @param query The query parameters for pagination and filtering.
   * @returns A promise resolving to an object containing paginated More Games data.
   */
  public async getMoreGamesPaginated(query: MoreGamesQueryRaw): Promise<IMoreGamesList> {
    try {
      return await firstValueFrom(this.apiMoreGamesService.getMoreGamesPaginated(query));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.getMoreGamesError');
      throw error;
    }
  }

  /**
   * Retrieves detailed information about a More Games entry based on its internal ID.
   * @param id The internal ID of the More Games entry.
   * @returns A promise resolving to an object containing detailed More Games information.
   */
  public async getMoreGamesByInternalId(id: string): Promise<IMoreGamesDetails> {
    try {
      return await firstValueFrom(this.apiMoreGamesService.getMoreGames(id));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.getMoreGamesByIdError');
      throw error;
    }
  }

  /**
   * Creates a new More Games entry.
   * @param moreGamesForm The form data for creating the More Games entry.
   * @returns A promise indicating the success of the operation.
   */
  public async createMoreGames(moreGamesForm: FormGroup<IMoreGamesForm>): Promise<void> {
    const moreGamesRaw: IMoreGamesRaw = MoreGamesService.getMoreGamesRaw(moreGamesForm);

    try {
      await firstValueFrom(this.apiMoreGamesService.createMoreGames(moreGamesRaw));
      this.moreGamesChanged.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesCreationErrorMessage');
      throw error;
    }
  }

  /**
   * Updates an existing More Games entry.
   * @param moreGamesForm The form data for updating the More Games entry.
   * @param moreGameId The ID of the More Games entry to be updated.
   * @returns A promise indicating the success of the operation.
   */
  public async updateMoreGames(moreGamesForm: FormGroup<IMoreGamesForm>, moreGameId: string): Promise<void> {
    const moreGamesRaw: IMoreGamesRaw = MoreGamesService.getMoreGamesRaw(moreGamesForm);
    moreGamesRaw.id = moreGameId;

    try {
      await firstValueFrom(this.apiMoreGamesService.updateMoreGames(moreGamesRaw));
      this.moreGamesChanged.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesEditErrorMessage');
      throw error;
    }
  }

  /**
   * Removes a More Games entry.
   * @param id The ID of the More Games entry to be removed.
   * @returns A promise indicating the success of the operation.
   */
  public async removeMoreGames(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiMoreGamesService.removeMoreGames(id));
      this.moreGamesChanged.next({deletedId: id});
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.removeMoreGamesError');
      throw error;
    }
  }

  /**
   * Initiates the export of More Games data to a CSV file.
   * @returns A promise resolving to a string representing the CSV data.
   */
  public async exportCsv(): Promise<string> {
    try {
      return await firstValueFrom(this.apiMoreGamesService.exportCsv());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesExport.exportCsvError');
      throw error;
    }
  }

  /**
   * Retrieves all available templates for More Games.
   * @returns A promise resolving to an array of template objects.
   */
  public async getAllTemplates(): Promise<ITemplate[]> {
    let templates = [];
    try {
      templates = await firstValueFrom(this.apiMoreGamesService.getAllTemplates());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesForm.getTemplatesError');
      throw error;
    }
    return templates;
  }

  /**
   * Initiates regeneration of all More Games entries in the backend.
   * @returns A promise that resolves upon successful regeneration.
   */
  public async regenrateAllMoreGames(): Promise<void> {
    try {
      await firstValueFrom(this.apiMoreGamesService.regenerateAllMoreGames());
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesRegenerate.regenerateAllMoreGamesError');
      throw error;
    }
  }

  /**
   * Initiates regeneration of a specific More Games entry identified by ID in the backend.
   * @param id The ID of the More Games entry to regenerate.
   * @returns A promise that resolves upon successful regeneration.
   */
  public async regenrateMoreGame(id: string): Promise<void> {
    try {
      await firstValueFrom(this.apiMoreGamesService.regenerateMoreGame(id));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesRegenerate.regenerateMoreGamesError');
      throw error;
    }
  }
}
